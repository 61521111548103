@import "assets/styles/globals.scss";
@import "./Roles.module.scss";

.page {
    // width: 900px;
    max-width: 900px;
    height: 100%;
    max-height: fit-content;
    min-height: fit-content;
    background-color: $gray20;
}
.container {
    padding: 2rem;
}