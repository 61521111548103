@import "assets/styles/globals.scss";

.settingsPage {
//   padding: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  width: 100%;
  background-color: $gray20;
//   overflow: hidden;
}

.settingsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 2px solid $gray40;
  height:fit-content;
  background-color: $white;
}


.header {
  @include header-3-text;
  margin: 0;
  height: fit-content;
}

.icon {
  display: flex;
  gap: 1em;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;

  i {
    color: $blue100;
    font-size: 1.7em;
  }
}

.filter {
  border-radius: $radius2;
  background-color: $sapphire20;
  color: $sapphire100;
  padding: 0.25rem 1rem;
  font-family: $poppins;
  font-size: 0.8rem;
  display: flex;
  gap: 0.25rem;
  cursor: pointer;

  .prop {
    color: $black;
  }
}


.delete {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}