@import "assets/styles/globals.scss";

.par {
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray110;
  width: 100%;
  // border: 2px solid black;
  white-space: pre-wrap;
}

.makeAdmin {
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray110;
  display: flex;
  gap: 1em;
  width: 100%;
}
.adduser {
  width: 100%;
  max-width: fit-content;
  height: fit-content;
  border-top-left-radius: $radius2;
  border-top-right-radius: $radius2;
}

.page {
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 100%;
  // max-width: 800px;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;
}

.peopleWithAccess {
  width: 800px;
}
