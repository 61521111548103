@import "assets/styles/globals.scss";

.page {
  display: flex;
  flex-direction: column;
  width: 420px;
  overflow: auto;
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1em;
  padding: 1rem;
  border-bottom: 1px solid $gray60;
}


.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
  border-top: 1px solid $gray60;
  background: $white;
  padding: 1rem;

}
.notificationContainer {
  cursor: pointer;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid $gray60;
  position: relative;

  &:hover {
    background-color: $gray20;
  }

  .avatar {
    max-width: 35px;
    max-height: 35px;
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    overflow: hidden;
  }

  .body {
    font-size: 0.8rem;
  }

  .notread {
    min-height: 8px;
    min-width: 8px;
    max-height: 8px;
    max-width: 8px;
    background-color: $blue100;
    border-radius: 50%;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  .read {
    min-height: 8px;
    min-width: 8px;
    max-height: 8px;
    max-width: 8px;
    border-radius: 50%;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  // .threeDots {
  //   color: $gray110;
  //   cursor: pointer;
  //   font-size: 1.2em;
  //   border-radius: $radius2;
  //   transition: 0.2s all;
  //   bottom: 0.5em;
  //   right: 1em;
  //   padding: 2px 5px;
  //   height: fit-content;

  //   &:hover {
  //     background-color: $gray25;
  //   }
  }

  