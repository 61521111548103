@import "assets/styles/globals.scss";

.link {
  outline: none;
  border: none;
  color: $blue100;
  background-color: transparent;
  font-family: $poppins;
  width: fit-content;
  cursor: pointer;
  padding: 0em;
  height: fit-content;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
}

.button {
  border-radius: $radius2;
  background-color: $white;
  color: $black;
  font-weight: 500;
  padding: 0 0.7rem;
  outline: none;
  border: none;
  font-family: $poppins;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  // border: 2px solid $blue100;

  &:hover {
    filter: brightness(0.9);
    // color: $blue100;
  }
}

.button.active {
  filter: brightness(0.9);
}

.button.shadow {
  box-shadow: $shadow6;
}

.button.lightblue {
  background-color: $info;
  border: 2px solid $info;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $info;
  // }
}
.button.lightblue.active {
  background-color: $white;
  color: $info;
}

.button.blue {
  background-color: $blue100;
  border: 2px solid $blue100;
  color: $white;
  // &:hover {
  //   filter: brightness(0.8);
  // }
}

.button.yellow {
  background-color: $yellow100;
  border: 2px solid $yellow100;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $yellow100;
  // }
}

.button.orange {
  background-color: $orange100;
  border: 2px solid $orange100;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $orange100;
  // }
}

.button.gray {
  background-color: $gray100;
  border: 2px solid $gray100;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $gray100;
  // }
}

.button.sapphire {
  background-color: $sapphire100;
  border: 2px solid $sapphire100;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $sapphire100;
  // }
}

.button.seafoam {
  background-color: $seafoam60;
  border: 2px solid $seafoam60;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $seafoam60;
  // }
}

.button.red {
  background-color: $red60;
  border: 2px solid $red60;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $red60;
  // }
}

.button.green {
  background-color: $green100;
  border: 2px solid $green100;
  color: $white;
  // &:hover {
  //   background-color: $white;
  //   color: $green100;
  // }
}

.cursor {
  border-radius: 5px;
}

.disable {
  background-color: $gray60;
  color: $white;
  border: 2px solid $gray60;
  filter: brightness(0.7);
  cursor: no-drop;
}

.settingsBox {
  top: 0.5rem;
  color: $black;
}

.tooltip {
  position: absolute;
  background: $black;
  color: white;
  font-size: 0.8rem;
  border-radius: $radius1;
  padding: 0.25rem 0.5rem;
  background-color: $gray110;
  box-shadow: $shadow6;
  z-index: 1;
}
