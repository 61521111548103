@import "assets/styles/globals.scss";

.page {
  display: flex;
  background-color: $gray20;
  flex-direction: column;
  padding-bottom: 15px;
}

.top {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  flex-wrap: wrap;
  // padding-bottom: 0;
  gap: 1rem;
  background-color: $white;
  border-bottom: 1px solid $gray60;
  .section {
    display: flex;
    align-items: center;
    gap: 1em;
  }
}

.name {
  @include header-4-text();
  font-size: 1.2em;
}
.menu {
  height: "fit-content";
  background-color: $white;
  border-bottom: 1px solid $gray40;
  padding: 0rem 1rem;
}

.imageContainer {
  position: relative;
  width: 200px;
  height: 200px;
  zoom: 0.2;
  // border: 2px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: $shadow5;
  // img {
  //   height: 200px;
  //   // width: 10em;
  // }
}

.imageEditor {
  position: relative;
  width: 200px;
  height: 200px;
  zoom: 1.1;
  // border: 2px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: $shadow5;

  // img {
  //   height: 200px;
  //   // width: 10em;
  // }
}
.editImage {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 800px;

  .header_4 {
    margin: 0;
  }
}

.section {
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius2;
  padding: 1rem;
  flex: 1;
  min-width: 300px;
  max-width: 700px;
  height: fit-content;
}

.pageSection {
  display: flex;
  padding: 2rem 3rem;
  gap: 1rem;
  flex-wrap: wrap;
  height: 100%;
}

.settingsBox {
  position: absolute;
  bottom: 1em;
  right: 0.7em;

  z-index: 130;
  background-color: $background;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  // align-items: center;

  cursor: default;
  overflow: hidden;

  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    cursor: pointer;
    i {
      font-size: 1.1em;
      color: $orange100;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}

.account {
  position: relative;
  width: 100%;
}

.mfa {
  background-color: $white;
  box-shadow: $shadow8;
  border-radius: $radius2;
  gap: 10px;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  color: $black;
}

.mfaReq {
  color: $sapphire80;
}

.grayed {
  color: $gray100;
}

.sigs {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.orgSig {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: $radius2;
  box-shadow: $shadow6;
  gap: 0.5rem;
  transition: 0.3s all;

  // &:hover {
  //   box-shadow: $shadow;
  //   background-color: $gray25;
  // }
}

.sigTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigName {
  width: fit-content;
  @include normal-text($black, 400, 0.8rem);
}

.html {
  color: $sapphire80;
  font-size: 0.75rem;
}

.sigPreview {
  display: flex;
  flex-direction: column;
  background-color: $white;

  border-radius: $radius2;
}

.authsection {
  border-radius: $radius2;
  box-shadow: $shadow6;
  overflow: hidden;

  .bottom {
    background-color: $gray20;
    display: flex;
    gap: .75rem;
    justify-content: space-between;
    padding: 1rem;
    flex-wrap: wrap;
  }
}

.label {
  font-size: .8rem;
  font-weight: 400;
}
