@import "assets/styles/globals.scss";
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 0;
    // display: none;
    cursor: pointer;
  }

  // 80% {
  //   display: block;
  // }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    pointer-events: all;
    cursor: text;
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $white;
}

.scrollable {
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth !important;
}

.inside {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 10px 0px;
  font-family: $poppins;
}

.title {
  width: fit-content;
}

.subtitle {
  width: fit-content;
  font-size: 0.8em;
  color: $gray100;
  font-weight: 600;
}

.download {
  a {
    text-decoration: none;
  }
}

.tableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-family: $poppins;
  // padding-top: 10px;

  .csvDownload {
    color: $gray110;
    padding: 2px 10px;
    background-color: $gray20;
    font-size: 0.8em;
    border-radius: 0.5em;

    display: flex;
    align-items: center;
    border: 1px solid $gray60;
    gap: 5px;
    font-family: $poppins;
    cursor: pointer;

    &:hover {
      background-color: $gray40;
    }
  }
}

.togglespread {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $gray60;
  // font-size: 0.8em;
  position: relative;

  i {
    position: absolute;
    right: 8px;
    top: 8%;
    z-index: 100;
    pointer-events: none;
  }
}

.simpleBar {
  border: 2px solid black;
}

.fulltable {
  border-spacing: 0;
  font-family: $poppins;
  border-radius: $radius2;
  box-shadow: $shadow;

  thead {
    background-color: $gray60;
    position: sticky;
    top: 0;
    z-index: 101;
    tr {
      background-color: $gray60;
      padding: 0.25em;
      margin-bottom: 10px;
    }
    th {
      font-family: $poppins;
      text-transform: uppercase;
      font-size: 0.7em;
      padding: 10px 10px;
      color: $white;
      background-color: $blue60;
      letter-spacing: 0.2em;
      text-align: left;
      font-weight: 600;

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
        &:hover {
          .overflow {
            display: block;
          }
        }
      }

      .overflow {
        @include animation("slide-left 1s 1");
        position: absolute;
        z-index: 100;
        display: none;
        background-color: $white;
        box-shadow: $shadow;
        padding: 5px 10px;
        border-radius: $radius4;
        max-width: 400px;
        cursor: text;
        white-space: wrap;
        color: $gray110;
        // transition: 1s display;
      }

      cursor: pointer;
      i {
        font-size: 0.8em;
        padding-left: 0.25em;
      }
    }
    th:first-child {
      border-top-left-radius: $radius2;
    }
    th:last-child {
      border-top-right-radius: $radius2;
    }
    th:last-child:hover {
      .threeDots {
        color: $blue100;
        cursor: pointer;
      }
    }
  }

  td {
    padding: 5px 10px;
    color: $gray110;
    font-size: 0.9em;
    width: fit-content;

    .text {
      white-space: wrap;
      &:hover {
        .overflow {
          display: block;
        }
      }
    }

    .overflow {
      @include animation("slide-left 1s 1");
      position: absolute;
      z-index: 100;
      display: none;
      background-color: $white;
      box-shadow: $shadow;
      padding: 5px 10px;
      border-radius: $radius4;
      max-width: 400px;
      cursor: pointer;
      white-space: wrap;

      i {
        color: $blue100;
      }

      &:hover {
        background-color: $gray60;
      }
    }
  }
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}

.columnHeader {
  white-space: nowrap;
}

.rowData:hover {
  background-color: $gray25;
}

.rowData {
  background-color: $white;
}
.gray {
  background-color: $gray20;
}

.editHeaders {
  position: absolute;
  right: 20px;
  top: 0em;
  background-color: white;
  border-radius: $radius2;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  max-height: 80vh;
  width: 200px;
  overflow: auto;
}
.editableHeader {
  text-align: left;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  color: $gray110;
}
.editableHeader:hover {
  background-color: $gray20;
}

.noDataContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.noData {
  padding: 40px;
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray100;
  font-weight: 600;
  // border: 2px solid $gray30;
  // border-radius: 5px;
  width: 90%;
  text-align: center;
}

.empty {
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.8rem;
  color: $gray100;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.saveSort {
  position: absolute;
  bottom: 0;
  right: 5px;
  bottom: 5px;

  display: flex;
  gap: 5px;
  align-items: center;
}

.save {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 2px solid $info;
  transition: 0.5s all;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  font-family: $poppins;
  color: $info;
  font-size: 0.8em;
  font-weight: 500;
  cursor: pointer;
  animation: fadeIn 0.5s;

  &:hover {
    color: $white;
    background-color: $info;
  }

  .upDown {
    display: flex;
    align-items: center;
  }
}

.dontSave {
  color: $black;
  cursor: pointer;
}

.scrollLeft {
  height: 30px;
  width: 30px;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 1em;
  top: 50%;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  color: $gray110;
  z-index: 100;
}

.scrollRight {
  height: 30px;
  width: 30px;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1em;
  top: 50%;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  color: $gray110;
  z-index: 100;
}

.spread {
  border-top: 1px solid $gray60;
  flex-grow: 1;
  width: 1400px;
}

.select {
  color: $gray110;
  padding: 2px 10px;
  background-color: $gray20;
  font-size: 0.8em;
  border-radius: 0.5em;
  width: 150px;
  display: flex;
  align-items: center;
  border: 1px solid $gray60;
  gap: 5px;
  font-family: $poppins;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}

.container:hover .searchBtn {
  opacity: 1;
}

.searchBtn {
  position: absolute;
  right: 50px;
  top: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $white;
  transition: 0.2s all;

  color: $gray110;
  padding: 5px 10px;
  border-radius: $radius4;
  box-shadow: $shadow;
  cursor: pointer;
  z-index: 115;

  opacity: 0;

  &:hover {
    color: $sapphire60;
  }
}

.searchDiv {
  width: 300px;
  position: absolute;
  right: 25px;
  top: 5px;
  display: flex;
  align-items: center;
  gap: 0em;
  z-index: 110;

  .close {
    color: $gray100;
    cursor: pointer;
    z-index: 110;
    margin-left: -25px;
    &:hover {
      color: $red60;
    }
  }
}

.gearIcon {
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
  background-color: $white;
  box-shadow: $shadow;
  padding: 5px 7px;
  border-radius: $radius8;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: $gray110;
    opacity: 1;
  }
}
