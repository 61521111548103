@import "assets/styles/globals.scss";

.page {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $gray22;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  width: 100%;
  overflow: hidden;
}

.searchBox {
  position: relative;
}

.tableHeader {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem;
  background-color: $white;
  border-bottom: 1px solid $gray60;
}

.surveys {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  gap: 1rem;
  padding: 2rem 1rem;
  font-size: 0.9rem;
  align-items: center;
  padding-bottom: 4rem;
  height: fit-content;
  overflow: auto;
  // min-height: 100vh;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 170px);
  }
}
