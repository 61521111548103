@import "assets/styles/globals.scss";

.content {
  background-color: $gray20;
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.inner {
  padding: 2rem;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius2;
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 700px;
  margin: auto;
}

.code-input-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.code-input {
  width: 56px; // Equivalent to w-14
  height: 56px; // Equivalent to h-14
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  border: 2px solid #cbd5e1; // Equivalent to border-gray-400
  background-color: #e5e7eb; // Equivalent to bg-gray-200
  border-radius: 8px; // Equivalent to rounded-md
  transition: all 0.2s ease-in-out;
  outline: none;

  &:focus {
    border-color: $blue100; // Equivalent to focus:border-blue-500
    background-color: #ffffff; // Equivalent to focus:bg-white
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
  }
}
