@import "assets/styles/globals.scss";

.tableContainer {
  width: 100%;
  overflow-x: auto;
  position: relative;
  box-shadow: $shadow6;
  border-radius: $radius2;
  height: 100%;
  background-color: $white;

  &::-webkit-scrollbar {
    height: 16px; // Chrome's default scrollbar height on macOS
  }
  &::-webkit-scrollbar-thumb {
    background: $gray60;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: $gray22;
    border-radius: $radius2;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  background-color: $white;

  .header {
    position: sticky;
    top: 0;
    background-color: $gray35;
    z-index: 10;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* Ensures consistent column widths */
    }

    .staticHeader {
      border-right: 1px solid $gray60;
    }
  }

  .checkboxHeader {
    border-right: 1px solid $gray60;
    max-width: 45px;
  }

  th {
    padding: 0.5rem 0.75rem;
    padding-right: 0.3rem;
    text-align: left;
    border-bottom: 1px solid $gray60;
    width: 200px;
    min-width: fit-content;
    color: $gray110;
    font-size: 0.8rem;
    font-weight: 500;
    position: relative;

    .thContent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        .sort {
          font-size: 0.7rem;
        }
      }

      .dots {
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $gray60;
          border-radius: $radius2;
          cursor: pointer;
        }
      }
    }

    .dropdown {
      position: absolute;
      top: 2rem;
      right: -200px;
      background-color: $white;
      box-shadow: $shadow6;
      border-radius: $radius2;
      z-index: 20;
      overflow: hidden;
      padding: 0.5rem 0rem;
      width: fit-content;

      .icon {
        min-width: 15px;
        display: flex;
        align-items: center;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          padding: 0.5rem 1rem;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          &:hover {
            background-color: $gray40;
          }
          i {
            min-width: 30px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .resizer {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 8px;
      cursor: col-resize;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .inside {
        background-color: $gray60;
        border-radius: $radius2;
        height: 18px;
        width: 2px;
        pointer-events: none;
      }
    }

    &:last-child {
      padding-right: 0.75rem;
      .resizer {
        right: 0.5rem;
      }
      .dropdown {
        right: 0;
      }
    }
  }

  .body {
    display: block;
    max-height: 100%;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      &:hover {
        background-color: $blue10;
      }
    }
  }

  td {
    padding: 0;
    border-bottom: 1px solid $gray60;
    font-size: 0.8rem;

    .value {
      padding: 0.4rem 0.75rem;
      height: 35px;
      // display: flex; // if you need flex for alignment

      border: 1px solid transparent;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      overflow: hidden; // hide overflow
      white-space: nowrap; // don't allow wrapping
      text-overflow: ellipsis; // add ellipsis for overflow
      min-width: 0; // allow flex item to shrink

      &:hover {
        border: 1px solid $blue100;
      }
    }

    .selectedCell {
      background-color: $blue5; // A light blue highlight
    }

    .borderTop {
      border-top: 1px solid $blue100;
    }
    .borderRight {
      border-right: 1px solid $blue100;
    }
    .borderLeft {
      border-left: 1px solid $blue100;
    }
    .borderBottom {
      border-bottom: 1px solid $blue100;
    }
  }
}
.rowNumbers {
  background-color: $gray35;
  border-right: 1px solid $gray60;
  text-align: center;
  font-weight: 500;
  color: $gray110;
}

/* Editing input style */
.editInput {
  width: 100%;
  height: 100%;
  border: 1px solid $blue100;
  box-sizing: border-box;
  font-size: 0.8rem;
  padding: 0 0.75rem;
}

/* Save / Cancel buttons */
.editButtons {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  // margin: 0.5rem 0;
  // width: 98%;
  padding-right: 1rem;
  position: absolute;
  bottom: 2rem;
  // right: 2rem;
  // background-color: $white;
  padding: 0.5rem;
  // border-top: 1px solid $gray60;
  // border-bottom: 1px solid $gray60;
}

.saveBtn,
.cancelBtn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: $radius1;
  font-size: 0.8rem;
  cursor: pointer;
}

.saveBtn {
  background-color: $blue100;
  color: $white;
}

.cancelBtn {
  background-color: $gray60;
  color: $white;
}

.editedCell {
  background-color: rgba(
    255,
    255,
    153,
    0.6
  ); // A light yellow highlight (adjust color/opacity as needed)
}
