@import "assets/styles/globals.scss";

.status {
  @include normal-text($gray110, 500, 0.7rem);
//   border: 1px solid $gray110;
  padding: 0.2rem 0.5rem;
  border-radius: $radius1;
  background-color: $gray25;
  width: fit-content;
}

.blue {
  background-color: $blue10;
//   border: 1px solid $blue100;
  color: $blue100;
}

.dullblue {
  background-color: $dullBlue00;
//   border: 1px solid $dullBlue120;
  color: $dullBlue120;
}

.orange {
  background-color: $orange20;
//   border: 1px solid $orange100;
  color: $orange100;
}

.yellow {
  background-color: $yellow20;
//   border: 1px solid $yellow100;
  color: $yellow100;
}

.red {
    background-color: $red10;
    // border: 1px solid $red60;
    color: $red60; 
}

.seafoam {
    background-color: $seafoam10;
    // border: 1px solid $seafoam100;
    color: $seafoam100;  
}

.sapphire {
    background-color: $sapphire20;
    // border: 1px solid $sapphire100;
    color: $sapphire100;  
}

.green {
    background-color: $green20;
    // border: 1px solid $green100;
    color: $green100;   
}