@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray60;
  border-radius: 5px;
  background: $white;
  // box-shadow: rgba(0, 0, 0, 0.144) 0px 2px 4px 
}

.config {
  padding: 5px;
  padding-left: 10px;
  border-bottom: 1px solid $gray60;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.label {
  font-size: 0.85rem;
  color: $sapphire80;
}

.emailContent {
  display: flex;
  min-height: 400px;
}

.subject {
  outline: none;
  border: none;
  font-family: $poppins;
  flex-grow: 1;
}
