/************** Colors **************/
$blue100: #15bcc7;
$blue60: #7fcfd3;
$blue40: #b5e1df;
$blue20: rgba(127, 207, 211, 0.08);
$blue10: #e7f5f7;
$blue5: #f5fcfc;
$blueDark: #0096a1;

$orange100: #ed9146;
$orange60: #edb57e;
$orange40: #f4e3c2;
$orange20: rgba(237, 181, 126, 0.15);

$seafoam100: #738c91;
$seafoam60: #8dabb2;
$seafoam30: #a6b8bc;
$seafoam10: #a6b8bc88;

$sapphire100: #2a627c;
$sapphire80: #6793a5;
$sapphire60: #a4c6d0;
$sapphire20: #a4c6d055;

$black: #050606;
$white: #ffffff;

$gray110: #616565;
$gray100: #a3a4a8;
$gray60: #d8d9d9;
$gray40: #efefef;
$gray35: #ebebeb;
$gray30: #e9e9e9;
$gray25: #f5f5f5;
$gray22: #fafafa;
$gray20: #f9f9f9;

$dullBlue120: #5a8181;
$dullBlue110: #5d8d8b;
$dullBlue100: #619994;
$dullBlue90: #66a69c;
$dullBlue80: #6cb2a3;
$dullBlue70: #74b9a8;
$dullBlue60: #7bc0ad;
$dullBlue50: #83c7b2;
$dullBlue40: #8ccbb7;
$dullBlue30: #95cebb;
$dullBlue20: #9ed2c0;
$dullBlue10: #a7d5c5;
$dullBlue00: #a7d5c584;

$red100: #ff523b;
$red60: #ff8878;
$red10: #feccc5;

$green100: #58b368;
$green20: #58b36939;

$yellow100: #faca50;
$yellow40: #fff6e0;
$yellow20: #faca502f;

$primary: #2a627c;
$seconday: #15bcc7;
$background: #ccd5e030;
$headerBackground: #e7f5f7;
$info: #7fcfd3;

$bpGreen: #a3cb98;
$bpYellow: #f6e3a2;
$bpRed: #f2a99f;

$radius1: 0.25rem;
$radius2: 0.5rem;
$radius3: 0.75rem;
$radius4: 1rem;
$radius5: 1.25rem;
$radius6: 1.5rem;
$radius7: 1.75rem;
$radius8: 2rem;

$shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$shadow2: $blue100 0px 5px 10px;
$shadow3: rgba(0, 0, 0, 0.15) 0px 5px 15px;
$shadow4: rgba(0, 0, 0, 0.3) 0px 3px 8px;
$shadow5: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$shadowLight: rgba(0, 0, 0, 0.15) 0px 3px 10px;
$shadow6: rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
$shadow7: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
$shadow8: rgba(0, 0, 0, 0.24) 0px 2px 3px;

/************** Text **************/
/* Fonts */
$poppins: "Poppins", sans-serif;
$montserrat: "Montserrat", sans-serif;
$ptsans: "PT Sans", sans-serif;
$questrial: "Questrial", sans-serif;
$georgia: Georgia, "Times New Roman", Times, serif;

h1 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: $black;
  font-weight: 500;
}

h2 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: $black;
  font-weight: 500;
}

h3 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: $black;
  font-weight: 500;
}

h4 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: $black;
  font-weight: 500;
}

h5 {
  font-family: "Poppins", sans-serif;
  
  margin: 0;
  color: $black;
  font-weight: 500;
}

h6 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: $black;
  font-weight: 500;
}

p {
  font-family: "Poppins", sans-serif;
}

span {
  font-family: "Poppins", sans-serif;
}

i {
  font-family: "Poppins", sans-serif;
}

section {
  font-family: "Poppins", sans-serif;
}

div {
  font-family: "Poppins", sans-serif;
}

small {
  font-family: "Poppins", sans-serif;
  font-size: .7rem;
}

@mixin col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@mixin row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.col {
  @include col;
}

.row {
  @include row;
}

// Typography presets

// helper function
@mixin text-color($contrast) {
  @if $contrast {
    color: $white;
  } @else {
    color: $black;
  }
}

@mixin normal-text($color: $gray110, $weight: 400, $size: 1rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
}

@mixin description-text($color: $gray110, $weight: 400) {
  font-family: $poppins;
  font-size: 0.7em;
  color: $color;
  font-weight: $weight;
}

@mixin header-1-text($color: $gray110, $weight: 600, $size: 2.5rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-2-text($color: $gray110, $weight: 600, $size: 2rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-3-text($color: $gray110, $weight: 600, $size: 1.5rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-4-text($color: $gray110, $weight: 600, $size: 1.25rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-5-text($color: $gray110, $weight: 600, $size: 0.9rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-6-text($color: $gray110, $weight: 600, $size: 0.8rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-7-text($color: $gray110, $weight: 600, $size: 0.7rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin header-8-text($color: $gray110, $weight: 600, $size: 0.6rem) {
  font-family: $poppins;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  margin: 0.5rem 0.5rem;
}

@mixin link($color: $blue100) {
  font-family: $poppins;
  color: $color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@mixin label-text($contrast: false) {
  @include text-color($contrast);
  font-family: $montserrat;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 1px;
}

@mixin button-text($contrast: false) {
  @include text-color($contrast);
  font-family: $montserrat;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8em;
}

/* Miscellaneous */
$container-radius: 5px;

/* Functions */
@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}
@include keyframes(show) {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}

//Classes
.header_1 {
  @include header-1-text($black, 500);
}

.header_2 {
  @include header-2-text($black, 500);
}

.header_3 {
  @include header-3-text($black, 500);
}

.header_4 {
  @include header-4-text($black, 500);
}

.header_5 {
  @include header-5-text($black, 500);
}

.header_6 {
  @include header-6-text($black, 500);
}

.section_label1 {
  height: 1rem;
  @include header-1-text($gray100, 400);
}

.section_label2 {
  height: 1rem;
  @include header-2-text($gray100, 400);
}

.section_label3 {
  height: 1rem;
  @include header-3-text($gray100, 400);
}

.section_label4 {
  height: 1rem;
  @include header-4-text($gray100, 400);
}

.section_label5 {
  height: 1rem;
  @include header-5-text($gray100, 400);
}

.section_label6 {
  height: 1rem;
  @include header-6-text($gray100, 400);
}

.section_label7 {
  height: 1rem;
  @include header-7-text($gray100, 400);
}

.section_label8 {
  height: 1rem;
  @include header-8-text($gray100, 400);
}

.label1 {
  @include header-1-text($sapphire80, 400);
  margin: 0;
}

.label2 {
  @include header-2-text($sapphire80, 400);
  margin: 0;
}

.label3 {
  @include header-3-text($sapphire80, 400);
  margin: 0;
}

.label4 {
  @include header-4-text($sapphire80, 400);
  margin: 0;
}

.label5 {
  @include header-5-text($gray110, 400);
  margin: 0;
}
.label55 {
  @include header-5-text($sapphire80, 400);
  font-size: 0.8rem;
  margin: 0;
}

.label6 {
  @include header-6-text($black, 400, 0.7rem);
  margin: 0;
  // letter-spacing: 1px;
}

.text_1 {
  @include normal-text($black, 400, 1rem);
}

.text_2 {
  @include normal-text($black, 400, 0.9rem);
}

.text_3 {
  @include normal-text($black, 400, 0.75rem);
}

.logo_name {
  @include header-4-text($gray110, 900, 1rem);
  font-family: $montserrat;
  letter-spacing: 1px;

  a {
    @include description-text($gray100, 200);
  }
}

.menu_item {
  color: $gray110;
  border-radius: 0.5em;
  transition: 0.3s all;
  width: 100%;
  cursor: pointer;
  padding: 0.2rem;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  @include normal-text($gray110, 400, 0.75rem);
  &:hover {
    background-color: $blue10;
    color: $blue100;
  }
}

.menu_item_active {
  background-color: $blue10;
  color: $blue100;
}

.settingsBox {
  // @include animation(open 0.3s 1);

  position: absolute;
  right: 0px;
  top: 2.5rem;
  white-space: nowrap;
  z-index: 130;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  cursor: default;
  overflow: hidden;

  .menuItem {
    // @include animation(show 0.3s 1);
    transition: none;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5rem 1rem;
    height: 40px;
    cursor: pointer;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}

.sortBox {
  width: 100%;
  display: flex;
  max-width: 1000px;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.infoTable {
  .infoRow {
    display: flex;
    justify-content: space-between;
    @include normal-text();
    padding: 0.5rem 0.7rem;
  }
  .gray {
    background-color: $gray22;
  }
  .dark {
    background-color: $gray35;
  }

  .top {
    border-top-left-radius: $radius2;
    border-top-right-radius: $radius2;
  }
  .bottom {
    border-bottom-left-radius: $radius2;
    border-bottom-right-radius: $radius2;
  }
}

.peopleWithAccess {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  gap: 5px;

  table {
    // padding: 0px 40px;
    border-collapse: collapse;
    font-family: $poppins;
    tr {
      &:hover {
        background-color: $gray30;
      }
    }

    td {
      padding: 5px 40px;
      font-family: $poppins;
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        color: $gray110;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0px;
        font-size: 0.9em;

        .name {
          font-weight: 500;
        }
        .email {
          color: $gray100;
        }
      }

      i {
        cursor: pointer;
        color: $gray110;
        transition: 0.3s all;
        margin-left: 5px;
        &:hover {
          color: $red60;
        }
      }
    }
    td:last-child {
      text-align: end;
    }
  }
}

.avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.link {
  @include link();
}

.blue {
  color: $blue100;
}

.dullblue {
  color: $dullBlue120;
}
.dullblue2 {
  color: $dullBlue70;
}
.info {
  color: $info;
}
.orange {
  color: $orange100;
}

.yellow {
  color: $yellow100;
}

.red {
  color: $red60;
}

.seafoam {
  color: $seafoam100;
}

.sapphire {
  color: $sapphire100;
}

.green {
  color: $green100;
}

.black {
  color: $black;
}
.gray {
  color: $gray110;
}

.blueDark {
  color: $blueDark;
}

.statusGreen {
  background-color: $green100;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
}

.statusRed {
  background-color: $red60;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
}

.dots {
  display: inline-block;
  width: fit-content;
}

.dots span {
  display: inline-block;
  animation: jump 1.2s infinite;
  margin: 0rem 0.15rem;
}

.dots span:nth-child(1) {
  animation-delay: 0s;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes jump {
  0%,
  60%,
  80% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-3px);
  }
}

.bubble {
  padding: 0.1rem 0.7rem;
  font-family: $poppins;
  border-radius: $radius1;
  font-weight: 400;
  font-size: 0.8rem;
}
.draft {
  border: 1px solid $orange100;
  background-color: $orange20;
  color: $orange100;
}
.live {
  border: 1px solid $blue100;
  background-color: $blue20;
  color: $blue100;
}
.closed {
  border: 1px solid $red60;
  background-color: $red10;
  color: $red60;
}

.neutral {
  border: 1px solid $sapphire100;
  background-color: $sapphire20;
  color: $sapphire100;
}

.open {
  border: 1px solid $green100;
  background-color: $green20;
  color: $green100;
}

.isEdit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid $gray30;
  border-radius: $radius1;
  padding: 0.25rem 0.5rem;
  @include normal-text($gray110, 400, 0.7rem);
  background-color: $white;
  span {
    @include link();
  }
}


// /* In your global CSS/SCSS file */
// .dragging {
//   -webkit-user-select: none; /* Chrome, Safari, Opera */
//   -moz-user-select: none;    /* Firefox */
//   -ms-user-select: none;     /* Internet Explorer/Edge */
//   user-select: none;         /* Standard */
// }



.runningprocess {
  width: 200px;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.runningbar {
  width: 50px;
  height: 100%;
  background: linear-gradient(90deg, $blue100, $blue100);
  border-radius: 5px;
  position: absolute;
  animation: runProcess 1.5s linear infinite;
  
}

@keyframes runProcess {
  0% {
    left: -50px;
  }
  100% {
    left: 200px;
  }
}