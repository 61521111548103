@import "assets/styles/globals.scss";
.pages {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
  z-index: 10;

  span {
    white-space: nowrap;
    font-family: $poppins;
    margin-right: 10px;
  }
  select {
    // -webkit-appearance: none;
    // appearance: none;
    margin-right: 1em;
    padding: 0.4rem;
    border: none;
    border-radius: 0.5em;
    font-family: $poppins;
    color: $gray110;
    outline: none;
    cursor: pointer;
    box-shadow: $shadow6;
    height: 2.1rem;
  }
}

.nextButton {
  border-radius: $radius1;
  border: none;
  background-color: white;
  min-height: 2.1rem;
  width: 2.1rem;
  font-family: $poppins;
  font-weight: bold;
  color: $gray110;
  cursor: pointer;
  box-shadow: $shadow6;
  // padding: 0px 4px;
}

.pageButton {
  border-radius: $radius1;
  border: none;
  background-color: white;
  width: fit-content;
  min-height: 2.1rem;
  width: 2.1rem;
  text-align: center;
  font-family: $poppins;
  font-weight: bold;
  color: $gray110;
  box-shadow: $shadow6;
  cursor: pointer;
}

.currPage {
  background-color: $blue100;
  border: 2px solid $blue100;
  border-radius: $radius1;
  min-height: 2.1rem;
  width: 2.1rem;
  text-align: center;
  color: white;
}

.item {
  @include description-text;
  font-size: 0.8em;
  padding-right: 5px;
  white-space: nowrap;
}

.dropDown {
  position: relative;
  justify-content: center;
  background: $white;
  border-radius: $radius1;
  font-size: 0.75rem;
  box-shadow: $shadow6;
  align-self: stretch;
}

.select {
  @include row;
  gap: 0.5rem;
  width: fit-content;
  padding: 0 0.7rem;
  cursor: pointer;
}

.options {
  @include col;
  position: absolute;
  // left: 0;
  max-height: 300px;
  overflow: auto;
  z-index: 1;
  box-shadow: $shadow6;
  border-radius: $radius1;
}

.option {
  padding: 0.5rem 0.7rem;
  @include row;
  transition: 0.3s all;
  background: $white;

  &:hover {
    background-color: $gray60;
    cursor: pointer;
  }
}
