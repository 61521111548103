@import "assets/styles/globals.scss";

.page {
  width: 600px;
  display: flex;
  flex-direction: column;
}

.folderItem {
  padding: 0.5rem 1.25rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  margin: .5rem 1rem;

  &:hover {
    background: $blue10;
  }

  &.highlighted {
    background: $blue10;
  }
}

.selected {
    background-color: $green20;
}

.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  width: 100%;
  border-top: 1px solid $gray60;
  background: $white;
  padding: 1rem;
}
