@import "assets/styles/globals.scss";

.fileManagerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.sidebar {
  min-width: 300px;
  max-width: 300px;
  min-height: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    border: none;
    border-bottom: 1px solid $gray60;
    height: fit-content;

    h3 {
      display: none;
    }
  }
}

.folderItem {
  padding: 1rem 1.25rem;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;

  &:hover {
    background: $gray20;
  }

  &.highlighted {
    background: $gray20;
  }
}

.fileListContainer {
  width: 100%;
  padding: 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $gray20;

  gap: 1rem;
  // overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    flex-grow: 1;
  }
}

.folderTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.fileGrid {
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
  }
}

.tableContainer {
  width: 100%;
  overflow: auto;
}
.table {
  border-collapse: collapse; /* Required for border-spacing */
  border-spacing: 0px;
  width: 100%;
  min-width: 600px;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  overflow: hidden;

  thead {
    th {
      padding: 0;

      background-color: $gray20;
      .headerContainer {
        background-color: $gray40;
        height: 40px;
        display: flex;
        align-items: center;
        @include header-6-text(black, 400, 0.8rem);
        white-space: nowrap;
        cursor: pointer;
        margin: 0;
        padding: 0.5rem 1rem;
        display: flex;
        gap: .5rem;
      }
      i {
        font-size: 0.7rem;
        color: $gray110;
      }
    }

    // th:first-child {
    //   .headerContainer {
    //     border-top-left-radius: $radius2;
    //     border-bottom-left-radius: $radius2;
    //   }
    // }
    // th:last-child {
    //   .headerContainer {
    //     border-top-right-radius: $radius2;
    //     border-bottom-right-radius: $radius2;
    //   }
    // }
  }
  tbody {
    tr {
      td {
        padding: 0.75rem 1rem;
        white-space: nowrap;
      }
      border-bottom: 1px solid $gray60;

      td:last-child {
        width: 50px;
      }

    }

    tr:last-child {
      border-bottom: none;
    }
  }
}

.draggableFile {
  cursor: pointer;
  transition: transform 0.2s, background 0.3s;
  touch-action: none;

  &:hover {
    background: $gray25;
    .tileOptions {
      opacity: 1;
      z-index: 1;
    }
    // transform: scale(1.05);
  }

  @media (max-width: 768px) {
  }

  .tileOptions {
    opacity: 0;
    display: flex;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    height: 30px;
    width: 30px;

    justify-content: center;
    align-items: center;
    border-radius: $radius1;
    transition: 0.3s all;
    i {
      color: $gray100;
    }
    &:hover {
      background-color: $white;
    }
  }
}

.dropAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d0ebdb;
  color: #48bb78;
  border: 1px solid #48bb78;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  animation: fadeInOut 2s ease-in-out;
  position: absolute;
  z-index: 1;
  top: 12%;
  gap: 1rem;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
