@import "assets/styles/globals.scss";

.page {
  padding: 2em 2em;
  padding-bottom: 5rem;
  font-family: $montserrat;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: $blue10;
  height: 100%;
  width: 100%;
  overflow: auto;
  align-items: center;
}

.formContainer {
  border-radius: $radius2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 600px;
  background-color: $white;
  box-shadow: $shadow;
  font-size: 0.8rem;
}

.canSend {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
}

.instruction {
  font-size: .9rem;
  color: $black
}

.sendOption {
  font-size: 0.9rem;
  color: $gray110;
}

.obscured {
  color: $black;
  font-size: 0.85rem;
}

.error {
  color: $red60;
  font-size: 0.85rem;
}

.clickable {
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: $black;
  }
}

.return {
  transform: rotateX(180deg);
  color: $sapphire80;
  font-size: 1rem;
}

// .line {
//   height: 1px;
//   background: #ccc;
//   width: 100%;
// }

.verified {
  color: $green100;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.pending {
  color: $sapphire100;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-left: -5px;
  img {
    width: 200px;
  }
  span {
    font-family: $poppins;
    color: $sapphire100;
    font-weight: 400;
    font-size: 1.5em;
    // border: 2px solid black;
  }

  // margin-bottom: 60px;
}
