.breadcrumb {
    display: flex;
    align-items: center;
  }
  
  .breadcrumbItem {
    display: flex;
    align-items: center;
  }
  
  .breadcrumbLink {
    background: none;
    border: none;
    color: #72bcd4;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
  
    &:hover {
      color: #50a3c6;
    }
  }
  
  .separator {
    margin: 0 8px;
    color: #aaa;
  }
  
  .current {
    font-weight: bold;
    color: #ddd;
  }