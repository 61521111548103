@import "assets/styles/globals.scss";

.container {
  @include col;
  gap: 10px;
}

.inactiveHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  img {
    max-width: 100%;
  }
}

.divider {
  height: 2px;
  background: $gray25;
}

.noImage {
  font-size: 0.85rem;
  color: $gray100;
  text-align: center;
}

.uploadedImage {
  max-height: 60px;
  max-width: 60px;
}

.imageSpace {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding-bottom: 10px;
}

.imageHolder {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  // border: 1px solid $gray60;
}

.adjuster {
  border: 1px solid black;
  height: 8px;
  width: 8px;
  background-color: white;
  position: absolute;
}

.bottomLeft {
  bottom: -4px;
  left: -4px;
  cursor: nesw-resize;
}

.bottomRight {
  bottom: -4px;
  right: -4px;
  cursor: nwse-resize;
}

.settings {
  background-color: $white;
  // border-bottom-right-radius: $radius4;
  // border-bottom-left-radius: $radius4;
  border-radius: $radius4;
  padding: 10px;
  gap: 15px;
}

.align {
  padding: 3px 7px;
  border-radius: $radius2;
  background: $white;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    box-shadow: $shadow6;
  }
}

.aligned {
  box-shadow: $shadow6;
}

.label {
  font-size: .85rem;
}