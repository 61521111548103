@import "assets/styles/globals.scss";

.body {
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  gap: 1em;
  padding: 1em 0em;
  // padding-top: 5px;
  background-color: $white;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  width: 100%;
}

.addSurvey {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px;
}

.add {
  font-size: 0.7rem;
  color: $black;
  font-family: $poppins;
  font-weight: 400;
  letter-spacing: 0.5px;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    color: $gray100;
  }
  // span {
  //   padding: 2px 5px;
  //   transition: 0.3s all;
  //   border-radius: $radius2;
  //   cursor: pointer;
  //   // box-shadow: $shadow;
  //   &:hover {
  //     background-color: $gray60;
  //   }
  // }
}

.addSettings {
  position: absolute;
  width: 100%;
  background-color: $gray20;
  box-shadow: $shadow;
  border-radius: $radius2;
  padding: 1em;
  top: 10px;
  // bottom: -130px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  z-index: 120;

  i {
    position: absolute;
    top: 5px;
    right: 10px;
    color: $gray110;
    cursor: pointer;

    &:hover {
      color: $red60;
    }
  }
}

.settingwithlabel {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
}

.searchQuestionInput {
  border: 2px solid $gray30;
  border-radius: $radius2;
  color: $gray110;
  font-size: 0.9em;
  padding: 8px 5px;
  font-weight: 400;
  font-family: $poppins;
  outline: none;
  transition: all 0.3s;
  width: 100%;
}
.searchQuestionInput:hover {
  border: 2px solid $gray100;
}
.searchQuestionInput:focus {
  border: 2px solid $gray100;
}
.searchQuestionInput::placeholder {
  color: $gray60;
}
.searchQuestionBox {
  position: relative;
  width: 100%;
}

.optionsBox {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 110;

  .options {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    background-color: white;
    min-width: 98%;
    // padding: .5em;
    max-height: 30em;
    overflow-y: auto;
  }

  .option {
    font-family: $poppins;
    font-size: 0.9em;
    padding: 0.5em;
    cursor: pointer;
    color: $gray110;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .option:hover {
    background-color: $blue20;
  }
}

.questionTypeConstraint {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    letter-spacing: 1px;
    font-weight: 400;
    color: $sapphire60;
  }
}

.currentData {
  width: 100%;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // border: 1px solid black;

  .projRow {
    position: relative;
    display: flex;
    flex-direction: column;
    // gap: 0.5em;
    gap: 6px;
    width: 100%;
  }
}

.chosenSurvey {
  display: flex;
  align-items: center;
  gap: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  color: $gray110;

  .projectName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  i {
    cursor: pointer;
    color: $gray110;
    transition: 0.3s all;
  }
  i:hover {
    color: $red60;
  }
  // a {
  //   font-size: 0.8em;
  //   color: $blue100;
  //   white-space: nowrap;
  //   font-weight: 500;
  //   text-transform: none;
  //   font-style: normal;
  //   position: absolute;
  //   right: 0;
  //   cursor: pointer;
  // }
  // a:hover {
  //   text-decoration: underline;
  // }
}

.questionContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // padding: 0.25em 0em;
  padding-left: 15px;
  width: 100%;
  overflow: hidden;
  // max-width: 100%;
  gap: 0.5em;
}

.mid {
  // border: 2px solid red;
  width: 100%;
  font-family: $poppins;
  color: $gray110;
  font-size: 0.8em;
  background-color: $gray40;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0px 5px;
}

.midItem {
  width: 100%;
  // border: 2px solid blue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.xicon {
  color: $gray110;
  font-size: 1em;
  cursor: pointer;
  padding: 0.3em 0.5em;
  // background-color: red;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.xicon:hover {
  color: $red60;
}

.plusicon {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  font-family: $poppins;
  font-size: 1em;
  color: $gray110;
  padding: 0em 0.15em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 5px 0px 2px;
  gap: 5px;

  span {
    font-size: 0.9em;
  }
}

.plusicon:hover {
  background-color: $gray40;
  // color: white;
}

.optionsBox2 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 1.5em;
  z-index: 100;

  .options {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    background-color: white;
    min-width: 98%;
    // padding: .5em;
    max-height: 30em;
    font-family: $poppins;
    font-size: 0.9em;
    overflow-y: auto;
  }

  .option {
    padding: 0.5em;
    cursor: pointer;
    color: $gray110;

    .qType {
      color: $blue60;
    }
  }
  .option:hover {
    background-color: $blue20;
  }

  .nullOption {
    position: relative;
    padding: 0.5em;
    color: $gray100;
    cursor: pointer;
  }
  .nullOption:hover {
    background-color: $gray20;
  }

  .qType {
    padding-left: 5px;
  }

  .blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffffbb;
    backdrop-filter: blur(3px);
    z-index: 110;

    color: $blue60;

    // div {
    //   background-color: #ffffffdd;
    //   // background-color: #ffffff66;
    //   backdrop-filter: blur(1px);
    //   border-radius: 5px;
    //   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    //   padding: 5px 10px;
    // }
  }
}

.searchFor {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px 10px;

  // width: 300px;
}

.search {
  width: 100%;
  border-radius: $radius2;
  background-color: $white;
  border: 2px solid $gray20;
  font-family: $poppins;
  color: $gray110;
  font-size: 1em;
  outline: none;
  padding: 2px 7px;
  transition: 0.3s all;

  &:hover {
    border: 2px solid $sapphire20;
    background-color: $white;
  }
  &:focus {
    border: 2px solid $sapphire20;
    background-color: $white;
  }
  &::placeholder {
    color: $gray100;
    font-size: 0.85em;
  }
}

.tagsAnchor {
  position: relative;
  // border: 1px solid black;
}

.tagOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 115;
  box-shadow: $shadow;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: $radius4;
  overflow: hidden;
}

.tagOption {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: 0.3s;
  background-color: $white;
  color: $gray110;
  font-size: 0.9em;

  &:hover {
    background-color: $gray30;
    cursor: pointer;
  }
}

.indicator {
  padding: 2px 10px;
  font-size: 0.85em;
  border-radius: $radius4;
}

.programIndicator {
  background-color: $dullBlue00;
  color: $dullBlue120;
}

.surveyTagIndicator {
  background-color: $orange20;
  color: $orange60;
}

.qTags {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 10px;
}

.potentialSurveyTags {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: $gray110;
  padding: 10px 0px;
}

.tags {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  padding-left: 20px;
}

.tag {
  font-size: 0.85em;
  padding: 3px 8px;
  background-color: $white;
  box-shadow: $shadowLight;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.qTagName {
  display: flex;
  align-items: center;
  gap: 7px;
}

.deleteTag {
  transition: 0.3s;
  color: $gray110;
  cursor: pointer;

  &:hover {
    color: $red60;
  }
}

.comparison {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
