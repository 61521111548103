@import "assets/styles/globals.scss";

.settingsPage {
  padding: 2rem;
  height: 100%;
  display: flex;
  gap: 3em;
  flex-direction: column;
  font-size: 0.9em;
  width: 100%;
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.settingsSection {
  display: flex;
  flex-direction: column;
  gap: 2em;
  height: 100%;
  max-width: 500px;
}

.header {
  @include header-3-text;
}

.icon {
  display: flex;
  gap: 1em;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;

  i {
    color: $blue100;
    font-size: 1.7em;
  }
}
