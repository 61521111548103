@import "assets/styles/globals.scss";

.surveys {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  padding: 2rem 1rem;
  font-size: 0.9rem;
  align-items: center;
  padding-bottom: 4rem;
  height: fit-content;
  min-height: 100vh;

  @media (max-width: 768px) {
    font-size: .8rem;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 170px);
  }
}

.add {
  box-shadow: $shadow;
  width: 250px;
  min-width: 250px;
  height: 200px;
  border-radius: $radius4;
  padding: 1em;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  color: $blue100;

  &:hover {
    background-color: $gray30;
    // .surveyTitle {
    //   font-size: 1.75em;
    // }

    // .surveyDescription {
    //   font-size: 0.8em;
    // }
  }
}

.more {
  display: flex;
  align-items: center;

  span {
    color: $blue100;
    font-family: $poppins;
    font-size: 0.8em;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}