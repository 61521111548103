@import "assets/styles/globals.scss";

.alertShorthand {
  @include col;
  // color: $sapphire80;
  padding: 0 10px;
  padding: 5px;
  border-radius: 6px;
  transition: 0.3s all;
  cursor: pointer;
  background-color: $gray20;
  box-shadow: $shadow6;
  overflow: hidden;

  &:hover {
    background-color: $gray40;
  }
}

.shorthand {
  color: $sapphire80;
}

.listItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fired {
  color: $black;

  span {
    color: $sapphire100;
  }
}

.settingsContainer {
  width: 100%;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0.3em;
  right: 0.5em;
  z-index: 100;
}

.questionName {
  font-family: $poppins;
  color: $gray110;
  padding-bottom: 5px;
  font-size: 0.7rem;
}

.alert {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-family: $poppins;
  background: $gray20;
  padding: 1.5em 2rem 2rem;
}

.opAndCondition {
  display: flex;
  width: fit-content;
  gap: 1em;
  align-items: center;
}

.divider {
  width: 98%;
  align-self: center;
  height: 1px;
  background-color: $gray60;
  border-radius: 5px;
}

.notifyWho {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.who {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-left: 10px;
}

.whoRow {
  display: flex;
  align-items: center;
  gap: 1em;
}

.whoHeader {
  color: $seafoam100;
  font-weight: 500;
}

.list {
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
}

.emailHolder {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  font-size: 0.8rem;
}

.email {
  border-bottom: 2px solid $sapphire20;
  padding: 0px 5px;
  color: $gray110;
}

.customField {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  font-size: 0.8rem;
}

.customFieldName {
  padding: 3px 6px;
  border-radius: 5px;
  background-color: $gray20;
  color: $sapphire80;
  box-shadow: $shadow6;
}

.withMessage {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.addBox {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 25px 0px 30px;
  border-top: 1px solid $gray60;
}

.apply {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid $blue20;
}

.emailToggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.option {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-family: $poppins;
  color: $gray110;
  &:hover {
    background-color: $gray20;
    cursor: pointer;
  }
}

.emailEntry {
  position: absolute;
  top: -10px;
  left: 25px;
  z-index: 130;
  border-radius: 10px;
  padding: 10px;
  background-color: $gray40;
  min-width: 270px;
  box-shadow: $shadow6;
}

.matching {
  display: flex;
  align-items: center;
  gap: 5px;
}

.matchingInfo {
  position: relative;
  font-size: 0.65rem;
  cursor: help;
  display: flex;
  align-items: center;
}
.matchingInfo:hover .matchingTooltip {
  display: flex;
}

.matchingTooltip {
  position: absolute;
  left: 2em;
  font-size: 1.2em;
  display: none;
  text-align: center;
  border-radius: 1em;
  box-shadow: $shadow6;
  background-color: $white;
  padding: 0.5em 1em;
  z-index: 140;
  font-size: 0.7rem;
}

.person {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  color: $sapphire80;
  font-size: 0.85rem;
}

.remove {
  color: $gray110;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: $red100;
  }
}

.search {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchResults {
  position: absolute;
  top: 120%;
  width: 100%;
  z-index: 1;
  // left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.9rem;
  max-height: 300px;
  overflow: auto;
  background-color: $white;
  box-shadow: $shadow6;
}
