@import "assets/styles/globals.scss";

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  gap: 2rem;
  margin-bottom: 18rem;
}


.maptable {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: start;
    @include normal-text($black);
    width: 40px;
    padding: 0.3rem 0.7rem;
  }

  td {
    padding: 0.1rem 0.5rem;
    width: 100%;
    height: 60px;
  }
  
}

.columninfile {
  width: 100%;
  border: 2px solid $gray40;
  background-color: $gray40;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: $radius1;
  padding: 0.6rem 0.5rem;
  font-family: $poppins;
  font-size: 0.9rem;
}

.box {
  text-align: center;
  border-radius: $radius4;
  border: 2px solid transparent;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  align-items: center;
  background-color: $white;

  .square {
    border: 4px solid $gray60;
    height: 140px;
    width: 140px;
    border-radius: $radius4;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 3rem;
      color: $blue100;
    }
  }
  .text_3 {
    color: $gray110;
    max-width: 600px;
  }

  &:hover {
    background-color: $blue10;
  }
}

.boxfill {
  background-color: $blue10;
}

.connection {
  display: flex;
  flex-direction: column;
  // box-shadow: $shadow6;
  // border: 2px solid $gray40;
  // padding: 1rem;
  // padding-top: 2rem;
  border-radius: $radius2;
  
  // gap: 2rem;
  width: 100%;
  // max-width: 700px;
  // borde
}


.field {
  border: 1px solid $gray100;
  padding: .5rem;
  border-radius: $radius1;
  background-color: $gray20;
}

.top {
  padding: 1rem;
  border-bottom: 2px solid $gray60;
}