@import "assets/styles/globals.scss";


.content {
    background-color: $gray20;
    width: 100%;
    height: 100%;
    padding: 1rem;
    
}


.inner {
    padding: 2rem;
    background-color: $white;
    box-shadow: $shadow6;
    border-radius: $radius2;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 700px;
    margin: auto;
}
