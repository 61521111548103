@import "assets/styles/globals.scss";

.imageContainer {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fbfc;

  input {
    cursor: pointer;
  }
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
//   border: 1px solid $gray60;
}
.edit {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  button {
    opacity: 0;
  }

  &:hover {
    backdrop-filter: blur(10px);
    button {
      opacity: 1;
    }
  }
}

.disable {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  // background-color: $blue100;
  // pointer-events: none;
}

.alt {
  background-color: $white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 0.9em;
  font-family: $poppins;
  color: $gray110;
}
