@import "assets/styles/globals.scss";

.text {
  color: $black;
  font-size: 0.9rem;
  max-width: 85%;
}

.domain {
  @include row;
  justify-content: space-between;
  padding: 12px 15px;
  box-shadow: $shadow6;
  border-radius: $radius2;
  background-color: $white;
  position: relative;

  &:hover .options {
    opacity: 1;
  }
}

.status {
  @include row;
  gap: 10px;
  width: fit-content;
  font-size: .9rem;
}

.name {
  color: $blue100;
  font-size: 0.9rem;
}

.at {
  color: $gray100;
  font-size: .9rem;
  padding-bottom: 10px;
}

.options {
  color: $black;
  position: absolute;
  top: 0px; 
  right: 0px;
  opacity: 0;
  transition: .4s all;
  font-size: 1rem;
  line-height: 1rem;
  transition: .4s all;
  @include row;
  width: fit-content;
  gap: 3px;
}

.star {
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: .65rem;
  line-height: .65rem;
  color: $blue100;
  
  &:hover .makePrimary {
    display: flex;
    @include animation(show .3s);
  }
}

.makePrimary {
  display: none;
  transition: .4s all;
}

.remove {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;

  &:hover {
    color: $red100;
  }
}