@import "assets/styles/globals.scss";

.sideMenu {
  position: relative;
  height: 100%;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s all;
  border-right: 1px solid $gray60;
  background-color: $white;
}

.expanded {
  width: 250px;
  transition: 0.2s all;
}

.collapsed {
  width: 90px;
  transition: 0.2s all;
  padding: 0.25rem 0.25rem;
}

.topMenu {
  padding: 0.5rem 1rem;
}

.bottomMenu {
  padding: 0.5rem 1rem;
}

/* Mobile */
@media (max-width: 768px) {
  .sideMenu {
    transform: translate(-120%);
    position: fixed;
    height: 100vh;
    box-shadow: $shadow6;
    z-index: 10000;
  }
  .mobileShow {
    transform: translateX(0);
  }

  .mobile {
    @include animation("slide-left .2s 1");
    padding: 0.5rem;
    width: 280px;
    transition: 0.2s all;
  }
  .mobileShowMenu {
    background-color: $white;
    padding: 0.6rem 0.8rem;
    bottom: 0.5rem;
    border-bottom: 1px solid $gray60;
    left: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .closeMobile {
    position: absolute;
    right: -70px;
    bottom: 1.5rem;
    color: $gray110;
    background-color: $white;
    // border: 1px solid $gray60;
    border-radius: $radius2;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    font-size: 1.2rem;
    padding-right: 0.1rem;
    cursor: pointer;
    z-index: 1;
    box-shadow: $shadow6;
    &:hover {
      color: $blue100;
    }
  }

  .topMenu {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: auto;
  }
}

/* Desktop */
@media (min-width: 768px) {
  .mobileShowMenu {
    display: none;
  }
  .closeMobile {
    display: none;
  }
  .topMenu {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: auto;
    gap: 0.2rem;
  }
}

.section_label7 {
  // color: $white;
}

.switchOrg {
  font-size: 0.6em;
  color: $gray110;
  text-align: left;
  padding-top: 0.5em;
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@include keyframes(slide-left) {
  0% {
    margin-right: 1000px;
  }
  100% {
    margin-right: 0px;
  }
}

.breakLine {
  border-radius: 5px;
  width: 40%;
  height: 2px;
  background-color: white;
  margin: 10px 0px;
}

.mainMenu {
  height: 100%;
  overflow-x: hidden;
  padding-top: 10px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuIcon {
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: white;
}

.menuItem {
  border-radius: 5px;
  width: 100%;
}
.sideMenu.expanded .menuItemBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.sideMenu.collapsed .menuItemBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.menuItemBox:hover {
  cursor: pointer;
}

.active {
  background-color: $sapphire20;
}

.menuItem {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  span {
    text-overflow: ellipsis;
    max-width: 50px;
  }
}

.mobileUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  text-align: left;
}

.userContainer:hover {
  cursor: pointer;
  background-color: $sapphire20;
}

.userContainer.active {
  background-color: $sapphire20;
  box-shadow: $shadow;
}

.userContainer {
  padding: 0.5rem 0.5rem;
  border-radius: $radius2;
  gap: 10px;
  transition: 0.3s all;
  width: 100%;
  display: flex;
  height: fit-content;
}

.openUser {
  display: grid;
  grid-template-columns: auto 100%;
  align-items: center;
  border-radius: 5px;
  width: 100%;
}
.closedUser {
  display: flex;
  // border-radius: 50%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  // background-color: black;
  // padding: 0;
}

.open {
  img {
    height: 40px;
  }
}
.closed {
  img {
    height: 40px;
  }
}

.mobile {
  img {
    height: 40px;
  }
}

.userInfo {
  color: $gray110;
  font-size: 13px;
  transition: opacity 0.2s ease-in;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  span {
    color: $gray110;
  }
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #333;
}

.container {
  margin-left: 300px;
  transition: margin-left 0.2s ease-in;
  padding: 50px;
  box-sizing: border-box;
}

.container.inactive {
  margin-left: 80px;
}

.logout {
  margin: 0 10px;
  color: white;
  font-size: 13px;
  transition: opacity 0.2s ease-in;
}

.logoavatar {
  position: relative;
  width: 200px;
  height: 200px;
  zoom: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: $shadow5;

  .logoname {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;
    padding: 0.5em;
  }
}

.logoavatar.smalllogoname {
  zoom: 0.2;
}

.logo {
  display: flex;
  align-items: center;

  .text_3 {
    color: #616565;
    font-weight: bolder;
    font-family: $montserrat;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
}

.logo.largeLogo {
  height: 3.5rem;
  justify-content: space-between;
  // padding-right: .2rem;
}

.smallLogo {
  width: 100%;
  height: 3.5rem;
  position: relative;
  cursor: pointer;
  transition: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;

  .list {
    position: absolute;
    top: 38%;
    color: $gray110;
    font-size: 0.7em;
  }
}

.poweredBy {
  width: 100%;
  @include normal-text($black, 500, 0.93rem);
  padding-top: 0.5rem;
  text-align: center;
  img {
    width: 70px;
  }

  span {
    color: $black;
  }
}

.imageContainer {
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 overflow: hidden;
 max-height: 3.5rem;
 border: 1px solid $gray60;
 border-radius: $radius2;
}


.arrow {
  position: absolute;
  right: 10px;
  // top: 50%;
  top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  cursor: pointer;
  color: $gray110;
  z-index: 1;
  &:hover {
    color: $black;
  }
}

.flip {
  transform: rotate(180deg);
}

