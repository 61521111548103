@import "assets/styles/globals.scss";

.page {
    font-family: $poppins;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
    background: $gray20;
    padding: 1rem;
    gap: 1rem;
}

.example {
    background-color: $gray40;
    height: 100%;
    flex: 1;
    border-radius: $radius2;
    padding: 3.5rem 7rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    min-width: 700px;
    img {
        // height: 800px;
        width: 1500px;
        border-radius: $radius4;
        box-shadow: $shadow6;
    }
    @media (max-width: 768px) {
        display: none;
    }

}

.form {
    flex: 1;
    max-width: 600px;
    min-width: 600px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 2rem;
    @media (max-width: 768px) {
       min-width: 100px;
       padding: .5rem;
    }
    overflow-y: auto;
    overflow-x:hidden;
}








.navProcess {
    height: 100%;
    background-color: $gray22;
    min-width: 400px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 1rem;
    justify-content: space-between;
    padding-bottom: 1rem;
    overflow: auto;
    border-right: 1px solid $gray60;
    @media (max-width: 768px) {
        flex-direction: column;
        border-right: none;
        display: none;
    }
}

.formContainer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 600px;
    background-color: $white;
    span {
        font-family: $poppins;
        color: $gray110;
    }
    span:hover {
        color: $gray110;
    }
    hr {
        width: 50%;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        border-radius: "5px";
        background-color: $gray100;
    }
    p {
        font-family: $poppins;
        margin-top: 30px;
        text-align: center;
        width: 100%;
        font-size: 12px;
        color: $gray110;
    }
}

.pageContent {
    display: flex;
   
    @media (max-width: 512px) {
        flex-direction: column;
    }
   
}


.content {
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 10rem;
}

.list {
    margin-left: 1rem;
    div {
        display: flex;
        gap: 1rem;
        font-size: .9rem;
        align-items: center;
    }
    i {
        color: green;
        font-size: 1.3rem;
    }
}


.box {
    border: 2px solid $gray40;
    padding: 2rem 2rem;
    border-radius: $radius2;
    height: fit-content;
}

.activeBox {
    border: 2px solid $blue100;
    background-color: $blue5;
}


.accountCreateBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: transparent;
    gap: 75px;
    padding-left: 20px;
}

.dropdown {
    background-color: blue;
}



.accountTypeContainer {
    border-radius: 0.75em;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
    flex-wrap: wrap;
}

.heading {
    font-size: 2rem;
    margin-bottom: 1rem; /* Space between heading and account types */
    text-align: center; /* Center the heading text */
}

.accountTypes {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.accountType {
    border-radius: 0.75em;
    padding: 10px 15px 20px 15px;
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    width: 100%;
    max-width: 300px;
    max-height: 284px;
    background-color: $white;
    box-shadow: $shadow;
    justify-content: center;
    span {
        font-family: $poppins;
        color: #15bcc7;
    }
    span:hover {
        color: $gray110;
    }
    hr {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        border-radius: "5px";
        background-color: $gray100;
    }
    p {
        font-family: $poppins;
        width: 100%;
        font-size: 14px;
        color: $gray110;
    }
    span.duration {
        font-size: 0.6em;
        color: $gray110;
    }
}

.buttonBox {
    display: flex;
    justify-content: center;
}

.checkmark {
    color: $green100;
    font-size: 1rem;
}

.accountTypeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $poppins;
    font-size: 2rem;
    color: $gray110;
    margin: 0.5rem 0rem;
}

.accountTypeHeader .current {
    font-size: 0.5em;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-left: -5px;
    
    span {
        font-family: $poppins;
        color: $sapphire100;
        font-weight: 400;
        font-size: 1.5em;
    }
}
.imageContainer2 {
    img {
        width: 100%;
    }
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 40px;
    align-items: stretch;
}

.validateButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 15px;
    gap: 10px;
}


.signInOptions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
}

.aLink {
    color: $blue100;
    cursor: pointer;
}
.aLink:hover {
    color: $blue100;
}

.register {
    font-family: $poppins;
    color: $gray110;
    font-size: 0.8em;
    padding: 1em 0em;
}

.typeBox {
    border-radius: $radius2;
    border: 2px solid $gray30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    transition: 0.3s all;
    cursor: pointer;
    position: relative;

    h3 {
        @include header-4-text($seafoam100);
        margin: 0em;
        font-weight: 400;
    }

    &:hover {
        background-color: $gray30;
        box-shadow: $shadow;
    }
}

.typeBoxFill {
    background-color: $gray30;
    box-shadow: $shadow;
}

.description2 {
    @include description-text;
    font-size: 0.8em;
    margin: 2em;
    text-align: center;
}

.check {
    position: absolute;
    right: 0.2em;
    top: 0.2em;

    i {
        color: $green100;
    }
}

.copyrightBar {
    bottom: 0;
    margin-top: 1%;
    justify-content: center;
}