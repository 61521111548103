@import "assets/styles/globals.scss";

.multipleChoice {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-right: 5px; // for the delete buttons
  color: $black;
}

.option {
  display: flex;
  align-items: center;
  gap: 20px;
}
.bar {
  // background-color: $blue100;
  padding: .25rem .5rem;
  border-radius: $radius2;
  transition: .3s all;
}

.optionTextEntry {
  flex-grow: 1;
  // font-size: .9em;
}

.optionText {
  font-family: $poppins;
  // color: $black;
  font-weight: 400;
  font-size: 0.9em;
  padding: 2px 5px;
}

.plainOption {
  font-family: $poppins;
  color: $gray110;
  font-weight: 400;
}
.addOption {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow;
  border-radius: $radius2;
  height: fit-content;
  width: fit-content;
  background-color: $white;
  cursor: pointer;
  transition: 0.3s all;
  padding: .5rem .5rem;
  color: $white;
  font-size: .9rem;
  font-weight: 500;
  background-color: $blue100;

  &:hover {
    background-color: $white;
    color: $gray110;
  }
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

 
  // -moz-transition: background-color 0.2s ease-in;
  // -o-transition: background-color 0.2s ease-in;
  // -webkit-transition: background-color 0.2s ease-in;
  // transition: background-color 0.2s ease-in;
  @include transition(background-color 0.2s ease-in);

  .checkmark {
    height: 21px;
    width: 21px;
    background-color: $gray30;
    border-radius: $radius2;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: $shadow;

    &:after {
      content: "";
      display: none;
      width: 9px;
      height: 9px;
      color: $white;
      background-color: $white;
      border-radius: $radius2;

      @include transition(background-color 0.2s ease-in);
    }

    &.multi {
      border-radius: $radius2;

      &:after {
        content: "\F26E";
        background-color: transparent;
        width: 11px;
        height: 11px;
        color: $white;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: bold !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &:hover input ~ .checkmark {
    background-color: $gray60;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    -webkit-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
  }

  input {
    display: none;
   
    &:checked ~ .checkmark {
      background-color: $blue100;
      -moz-transition: background-color 0.2s ease-in;
      -o-transition: background-color 0.2s ease-in;
      -webkit-transition: background-color 0.2s ease-in;
      transition: background-color 0.2s ease-in;
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.containerRank {
  // width: 35px;
  // height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // -moz-transition: background-color 0.2s ease-in;
  // -o-transition: background-color 0.2s ease-in;
  // -webkit-transition: background-color 0.2s ease-in;
  // transition: background-color 0.2s ease-in;
  @include transition(background-color 0.2s ease-in);

  .checkmarkRank {
    height: 20px;
    width: 20px;
    background-color: $gray30;
    border-radius: $radius2;
    display: flex;
    justify-content: center;
    align-items: center;
    .ranking {
      display: none;
      color: $white;
      font-size: 0.8em;
      font-family: $poppins;
    }

    // &:after {
    //   content: "";
    //   display: none;
    //   width: 11px;
    //   height: 11px;
    //   color: $white;
    //   background-color: $white;
    //   border-radius: 50%;

    //   @include transition(background-color 0.2s ease-in);
    // }

    &.multiRank {
      border-radius: $radius2;

      &:after {
        background-color: transparent;
        width: 11px;
        height: 11px;
        color: $white;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: bold !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &:hover input ~ .checkmarkRank {
    background-color: $gray60;
    border-radius: $radius2;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    -webkit-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
  }

  input {
    display: none;

    &:checked ~ .checkmarkRank {
      background-color: $blue100;
      -moz-transition: background-color 0.2s ease-in;
      -o-transition: background-color 0.2s ease-in;
      -webkit-transition: background-color 0.2s ease-in;
      transition: background-color 0.2s ease-in;
      .ranking {
        display: block;
      }
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.deleteButton {
  align-self: center;
  margin-left: 5px;
  color: $gray100;

  i:before {
    font-weight: bold !important;
  }
}
.deleteButton:hover {
  cursor: pointer;
}

.otherLabel {
  font-family: $poppins;
  color: $sapphire60;
  font-weight: 400;
  font-size: 0.8em;
}

.textField {
  font-size: 0.9em !important;
  font-family: $poppins;
  font-weight: 400;
  width: 100%;
  border: none;
  // border-bottom: 2px solid #d8d9d9;
  background-color: $gray25;
  padding: 4px 7px;
  border-radius: $radius2;
  outline: none;
  cursor: text;
  // color: $black;
  transition: 0.3s all;
}

.textField:hover {
  // border-bottom: 2px solid $gray100;
  // background-color: $gray30;
}
.textField:focus {
  background-color: $gray25;
}

.otherOption {
  flex-grow: 1;
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.9em;
  padding: 2px 5px;
}

.otherOption:hover {
  .other {
    display: none;
  }
  .otherEntry {
    display: flex;
  }
}

.otherEntry {
  display: none;
}

.inputLine {
  font-family: $poppins;
  font-size: 1em;
  width: 100%;
  font-weight: 400;
  border: none;
  font-family: $poppins;
  outline: none;
  background-color: $gray20;
  border-radius: $radius2;
}
