@import "assets/styles/globals.scss";

.settingsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $poppins;
}

.buckets {
  padding: 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.bucket {
  display: flex;
  flex-direction: column;
}

.bucketName {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.85rem;
}

.propsList {
  color: $gray100;
  font-size: 0.7rem;
  padding-left: 5px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.edit {
  font-size: 0.75rem;
  color: $gray60;

  cursor: pointer;

  transition: 0.3s all;

  &:hover {
    color: $seafoam100;
  }
}

.createBucket {
  width: fit-content;
  font-size: 0.75rem;
  color: $gray60;
  padding: 0px 10px 0px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    color: $seafoam100;
  }
}

.runBuckets {
  display: flex;
  flex-direction: column;
  gap: 5px;

  color: $gray110;
  font-size: 0.7rem;
  padding: 5px 10px;
}

.comment {
  padding-left: 10px;
}

.runSwitch {
  display: flex;
  align-items: center;
  gap: 15px;
}

.auto {
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 10px;
  font-size: 0.75rem;
  color: $gray110;

  transition: 0.3s all;
}

.off {
  color: $gray100;
}

.createLabel {
  font-size: 0.8rem;
  color: $sapphire60;
  border-bottom: 1px solid $sapphire20;
  width: fit-content;
  padding-right: 30px;
}
