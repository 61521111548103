@import "assets/styles/globals.scss";

.page {
  width: 100%;
  height: fit-content;
  min-width: 800px;
  display: flex;
  flex-direction: column;
  border-radius: $radius2;
  box-shadow: $shadow6;
}

.content {
  background-color: $gray20;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  border-bottom-left-radius: $radius2;
  border-bottom-right-radius: $radius2;
}

.table {
  width: 100%;
  border-collapse: collapse; /* Important: Allows border-spacing */
  table-layout: fixed;
  background-color: $gray40;
  border-radius: $radius2;

  thead {
    th {
      text-align: start;
      @include normal-text($black, 400, 0.8rem);
      padding: 0.25rem 0.7rem;
      padding-top: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  tbody {
    tr:last-child {
      td {
        padding-bottom: 0.5rem;
      }
    }

    tr {
      td {
        // width: 75px; /* Set fixed width */
        text-overflow: ellipsis; /* Adds "..." if content is too long */
        white-space: nowrap; /* Prevents wrapping */
        padding: 0.25rem 0.5rem;
        // border: 1px solid black;
      }

      td:first-child {
        border-top-left-radius: $radius2;
        border-bottom-left-radius: $radius2;
      }
      td:last-child {
        border-top-right-radius: $radius2;
        border-bottom-right-radius: $radius2;
      }
    }
  }
}
