@import "assets/styles/globals.scss";

.page {
  font-family: $poppins;
  display: flex;
  flex-direction: column;
//   background-image: linear-gradient(to bottom right, $white, $info);
background-color: $blue10;
  height: 100%;
  width: 100%;
  color: $gray110;
  overflow: auto;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: $montserrat;
}

.captcha {
//   width: 100%;
margin-top: .75rem;
margin-bottom: .5rem;
}

.formContainer {
  justify-content: center;
  border-radius: $radius2;
  padding: 40px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 450px;
  height: fit-content;
  max-width: 800px;
  background-color: $white;
  box-shadow: $shadow;
  margin: auto;

  span {
    font-family: $poppins;
    color: $gray110;
  }
  span:hover {
    color: $gray110;
  }
  hr {
    width: 50%;
    margin-top: 20px;
    margin-left: auto;
    border-radius: "5px";
    background-color: $gray100;
  }
  p {
    font-family: $poppins;
    margin-top: 30px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: $gray110;
  }
}

.loginBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 0.75em;
  padding: 30px 0px 30px 40px;
  gap: 75px;
}

.mainPageImage {
  display: block;
  width: 50%;
  object-fit: cover;
  height: 600px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .mainPageImage {
    display: none;
  }

  .formContainer {
    background-color: transparent;
    box-shadow: none;
    margin: 0 auto;
    min-height: 100vh;
    padding: 2rem;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-left: -5px;
  margin-bottom: 1rem;
  img {
    width: 200px;
  }
  span {
    font-family: $poppins;
    color: $sapphire100;
    font-weight: 400;
    font-size: 1.5em;
    // border: 2px solid black;
  }

  // margin-bottom: 60px;
}

.imageContainer2 {
  img {
    width: 100%;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
}

.buttonContainer > div {
  flex: 1;
  display: flex;
}

.buttonContainer button {
  width: 100%;
  flex: 1;
  margin: 0;
}

.splitPageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  // gap: 40px;
  // height: 100%;
  flex-wrap: wrap-reverse;
  margin-top: 2em;
  // margin: auto;
  // border: 2px solid black;
}

.signInOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
}

.aLink {
  color: $blue100;
  cursor: pointer;
}
.aLink:hover {
  color: $blue100;
}

.animationContainer {
  // border: 2px solid black;
  // text-align: center;
  position: relative;
  padding: 1em;
  span {
    font-family: $poppins;
    color: $sapphire100;
    font-weight: 400;
    font-size: 1.7em;
    position: absolute;
    // border: 2px solid black;
    width: 100%;
    text-align: center;
    top: 1.5em;
    z-index: 10;
  }
}

.register {
  font-family: $poppins;
  color: $gray110;
  font-size: 0.8em;
  padding-bottom: 20px;
}

.copyrightBar {
  // position: absolute;
  // bottom: 0;
  width: 100%;
}
