@import "assets/styles/globals.scss";

.textField {
  background-color: $white;
  color: $gray110;
  outline: none;
  width: 100%;
  font-family: $poppins;
  transition: all 0.3s;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border: 2px solid $white;
  height: 2rem;

  &.disabled {
    background-color: #ffffff00;
    @include transition(background-color 0.2s ease-in);
    padding-left: 0;
    padding-right: 0;
  }
  &::placeholder {
    color: $gray60;
  }
}

.searchField {
  display: flex;
  gap: 0.25em;
  border-radius: $radius2;
  border: 2px solid white;
  background-color: $white;
  width: 20em;
  align-items: center;
  overflow: hidden;
  padding-left: 1em;

  i {
    color: $gray60;
  }
}

.shadow {
  box-shadow: $shadow6;
}

// .textField:hover {
//   border: 2px solid $gray60
// }

// .textField:focus {
//   border: 2px solid $gray60
// }
