@import "assets/styles/globals.scss";

.page {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  // align-items: center;
  font-family: $poppins;
  gap: 1rem;
  padding: 2rem;
}


.updates {
  border: 1px solid $gray60;
  border-radius: $radius2;
  width: fit-content;
  gap: .5rem;
  span {
    display: flex;
    align-items: center;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  color: $gray110;
}

.icon {
  @include link;
  display: flex;
  gap: 0.3em;
  color: $blue100;
  align-items: center;
  font-size: 0.9em;
}

.search {
  display: flex;
  width: 100%;
  height: fit-content;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1em;
}


.download {
  @include link;
  font-size: 1.1em;
}

.table {
  border-collapse:separate; /* Required for border-spacing */
  border-spacing: 0 10px;
  width: 100%;
  min-width: 600px;
  // background-color: $white;
  // border-radius: $radius2;

  thead {
    th {
      padding: 0;

      background-color: $gray20;
      .headerContainer {
        background-color: $gray40;
        height: 40px;
        display: flex;
        align-items: center;
        @include header-6-text(black, 400, 0.8rem);
        white-space: nowrap;
        cursor: pointer;
        margin: 0;
        padding: 0.5rem 1rem;
        display: flex;
        gap: .5rem;
      }
      i {
        font-size: 0.7rem;
        color: $gray110;
      }
    }
    // th {
    //   text-align: left;
    //   padding: 0.5rem 0;
    //   padding-left: 0.5rem;
    //   @include header-6-text(black, 400, 0.8rem);
    //   // border-right: 1px solid $gray60;
    //   white-space: nowrap;
    //   cursor: pointer;
    //   background-color: $blue10;

    //   i {
    //     font-size: 0.7rem;
    //     color: $gray110;
    //   }
    // }
    th:last-child {
      padding-right: 0.5rem;
    }
  }
  tbody {
    .title {
      font-size: .8rem;
    }
    tr {
      background-color: $white;
      box-shadow: $shadow6;
      border-radius: $radius2;
      &:hover {
        td {
          background-color: $gray25;
          cursor: pointer;
        }
      }
    }
    tr {
      td {
        padding: 0.5rem 0;
        padding-left: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        // border-top: 1px solid $gray60;
      }
      td:first-child {
        padding-left: 1rem;
        width: 60px;
        border-top-left-radius: $radius2;
        border-bottom-left-radius: $radius2;
      }
      td:last-child {
        border-top-right-radius: $radius2;
        border-bottom-right-radius: $radius2;
        width: 60px;
        overflow: visible;
        padding-right: .5rem;
      }
    }
    tr:last-child {
      display: none;
    }
  }
}
