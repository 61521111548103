@import "assets/styles/globals.scss";



.imageImporter {
  display: flex;
  flex-direction: column;

  padding: 5px;
  font-family: $poppins;

  max-height: 300px;
  overflow: auto;
}

.chooseTitle {
  align-self: center;
  border-bottom: 1px solid $gray110;
  padding: 0px 12px;
  font-size: .85rem;
  color: $black;
}

.imageUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border-bottom: 2px solid $gray25;
  padding: 20px 10px;

  font-size: 0.7rem;
}

.imageCatalog {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  max-height: 600px;
  overflow: auto;
}

.upload {
  display: flex;
  align-items: center;
  gap: 7px;
  color: $black;
  background-color: $gray30;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s all;
  padding: 5px 15px;
  // border: 2px solid $gray60;

  &:hover {
    background-color: $gray60;
  }
}

.use {
  color: $white;
  background-color: $sapphire80;

  &:hover {
    // color: $black;
    background-color: $sapphire100;
  }
}

.catalogPick {
  width: 80px;
  height: 80px;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $gray40;
  }
}

.chosenImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 0px;
}

.past {
  color: $gray100;
  font-size: .8rem;
}

.seeOthers {
  font-size: .8rem;
  color: $sapphire80;
  align-self: flex-end;
  margin-bottom: 10px;
  cursor: pointer;

  transition: .3s all;

  &:hover {
    color: $sapphire60;
  }
}
