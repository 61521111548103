@import "assets/styles/globals.scss";

.pages {
  background-color: $gray20;
  height: 100;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.tabs {
  width: 100%;
  padding: 1rem 1rem;
  max-height: fit-content;
  border-bottom: 1px solid $gray60;
  background-color: $white;
  display: flex;
  gap: 1rem;
}
