@import "assets/styles/globals.scss";

.input {
  flex-grow: 1;
  width: 100%;

  .labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 0 0.1rem;
    padding: 0.1rem 0.5rem;
    align-items: flex-end;
    gap: 10px;
  }
  font-size: 0.9rem;
}

.bool {
  // height: 100%;
  border: 1px solid $white;
  background-color: $white;
  border-radius: $radius2;
  padding: 0rem 1rem;
  min-height: 2.5rem;
  box-shadow: $shadow6;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    border: 1px solid $blue100;
  }
}

.listField {
  border: 1px solid $white;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  padding: 0rem 1rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
}

.listFieldInput {
  width: 100%;
}

.placeholder {
  color: $gray60;
}

.listFieldInputMultiple {
  width: 100%;
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
  padding: 0.2rem 0rem;
}

.disableList {
  background-color: $gray40;
  cursor: auto;
}

.disableIcon {
  color: $gray100;
  pointer-events: none;
}

.invalid {
  border: 1px solid $red60;
  background-color: $red10;
}

@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}
@include keyframes(show) {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}

.dropdown {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  max-height: 14rem; /* Prevents excessive growth */
  position: absolute;
  background-color: $white;
  box-shadow: $shadow6;
  width: 100%;
  min-width: 10px;
  height: 0px;
  border-radius: $radius2;
  border: none !important;
  font-family: $poppins;
  // font-size: 0.9em;
  color: $gray110;
  overflow: auto;
  left: 0;
  top: 108%;
  z-index: 100;

  .option {
    @include animation(show 0.3s 1);
    padding: 0px 20px;
    height: 2.46rem;
    transition: 0.3s all;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: $black;

    &:hover {
      background-color: $blue10;
      .helperText {
        opacity: 1;
      }
    }
  }
}

// .dropdown.open {
//   max-height: 14rem; /* Set a maximum height */
// }

.chosenOption {
  display: flex;
  align-items: center;

  .chosenOptionRemove {
    background-color: $gray25;
    border-right: 1px solid $gray60;
    border-top: 1px solid $gray60;
    border-bottom: 1px solid $gray60;
    border-top-right-radius: $radius1;
    border-bottom-right-radius: $radius1;
    padding: 0.2rem 0.2rem;
    transition: 0.3s all;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $red60;
    }
  }

  .chosenOptionName {
    background-color: $gray25;
    border-left: 1px solid $gray60;
    border-top: 1px solid $gray60;
    border-bottom: 1px solid $gray60;
    border-top-left-radius: $radius1;
    border-bottom-left-radius: $radius1;
    padding: 0.2rem 0.2rem;
  }
}
