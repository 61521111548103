@import "assets/styles/globals.scss";

.page {
  background-color: $gray20;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.inviteUser {
  background-color: $gray20;
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: fit-content;
  min-height: fit-content;
  border-radius: $radius2;
  // overflow: hidden;
}

.content {
  // padding: 2rem;
  // overflow-x: auto;
}

.users {
  // background-color: $white;
  padding: 2rem;
  // border-radius: $radius2;
  // border-bottom-right-radius: $radius2;
  // border-bottom-left-radius: $radius2;
  // box-shadow: $shadow6;
  // overflow: hidden;
  height: fit-content;
  min-height: fit-content;
  width: 1200px;
  margin: 0 auto;
  small {
    color: $gray110;
  }
}

.bigOption {
  width: 300px;
}

.description2 {
  width: 100%;
  white-space: wrap;
}

.date {
  // color: $gray110;
}

.table {
  border-collapse: collapse; /* Required for border-spacing */
  border-spacing: 0px;
  width: 100%;
  min-width: 600px;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius2;
  overflow: hidden;

  thead {
    th {
      padding: 0;

      background-color: $gray20;
      .headerContainer {
        background-color: $gray40;
        height: 40px;
        display: flex;
        align-items: center;
        @include header-6-text(black, 400, 0.8rem);
        white-space: nowrap;
        cursor: pointer;
        margin: 0;
        padding: 0.5rem 1rem;
        gap: 0.4rem;
        align-items: center;
        i {
          font-size: .6rem;
        }
      }
      i {
        font-size: 0.7rem;
        color: $gray110;
      }
    }

    // th:first-child {
    //   .headerContainer {
    //     // border-top-left-radius: $radius2;
    //     // border-bottom-left-radius: $radius2;
    //   }
    // }
    // th:last-child {
    //   .headerContainer {
    //     // border-top-right-radius: $radius2;
    //     // border-bottom-right-radius: $radius2;
    //   }
    // }
  }
  tbody {
    tr {
      td {
        padding: 0.75rem 1rem;
        white-space: nowrap;
        cursor: pointer;
      }
      border-bottom: 1px solid $gray60;

      td:last-child {
        width: 50px;
      }

      &:hover {
        background-color: $gray25;
      }
    }
    tr:last-child {
      border-bottom: none;
    }
  }
}
