@import "assets/styles/globals.scss";

.page {
  font-family: $poppins;
  height: 100vh;
  width: 100%;
  
}

.imageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 200px;
  }
  padding-bottom: 30px;

  // margin-bottom: 60px;
}

.pageContent {
    font-size: 1.2em;
    text-align: center;
    width: 100%;
  a {
        color: $blue100;
    }
}
