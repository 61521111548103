@import "assets/styles/globals.scss";

.pageContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  background-color: $gray25;
}

.survey {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  transition: 0.3s all;
  max-width: 900px;
}

.apage {
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.pageBreak {
  padding: 0.5rem 1rem 1.5rem;
  font-family: $poppins;
  font-size: 0.8rem;
  color: $gray100;
  font-weight: 400;
  line-height: 1rem;
}

.pageLine {
  height: 3px;
  border-radius: 5px;
  background-color: $gray30;
  width: 20px;
}

.addSomething {
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    color: $blue100;
  }
}

.deletePage {
  transition: 0.3s all;
  color: $gray110;
  &:hover {
    color: $red60;
  }
}

.pageHeader {
  padding: 1rem 0;
  border-radius: $radius4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 2px solid transparent;
  transition: 0.3s all;
  cursor: pointer;
}

.mobileImage {
  position: relative;
}

.mobile {
  position: absolute;
  top: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.mobileC {
  width: 415px;
  max-width: 415px;
  overflow: auto;
  max-height: 770px;
}

.finishedPage {
  border: 2px solid transparent;
  margin: 1rem;
}

.aactive {
  border-radius: $radius2;
  border: 2px solid $blue100;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px 0px;
}

.editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  overflow: auto;
  height: 100%;
}

.constructionZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 30px 0;
  z-index: 0;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // gap: 2px;
}

.pageChangeContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.settingsContainer {
  background-color: $white;
  height: 100%;
  top: 0;
  box-shadow: $shadow6;
  transition: 0.3s all;
  z-index: 1;
}
.nav {
  top: 0;
  // z-index: ;
  background-color: $white;
  width: 100%;
  padding: 1rem;
  // box-shadow: $shadow6;
  border-bottom: 2px solid $gray40;
}

.settingsContent {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
  transition: 0.3s all;
  height: 100%;
}

.noQuestions {
  border: 2px solid $gray30;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  color: $gray110;
  background-color: white;

  span {
    color: $blue100;
    cursor: pointer;
  }
}

.disablePage {
  position: absolute;
  width: 100%;
  background-color: transparent;
  height: 100%;
}

.saveStatus {
  position: absolute;
}

.navButton {
  height: 100%;
  border: 2px solid $blue100;
  padding: 0.2em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: $blue100;
  cursor: pointer;
  transition: 0.3s all;
  max-height: 35px;
}

.navButton:hover {
  background-color: $blue100;
  color: $white;
}

.expandedPreview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 140;
  overflow: auto;
}

.close {
  padding-top: 20px;
  position: fixed;
  right: 20px;
  top: 10px;
  cursor: pointer;
  z-index: 106;
  display: flex;
  gap: 10px;
  align-items: center;
}

.addBox {
  border-radius: 5px;
  display: flex;
  font-family: $poppins;
  transition: all 0.3s;
  box-shadow: $shadow;

  cursor: pointer;
  span {
    color: $white;
    transition: all 0.3s;
    height: 40px;
    font-weight: 600;
    font-size: 0.9em;
    border: 2px solid $blue100;
  }
  span:first-child {
    background-color: $blue100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
  }

  span:first-child:hover {
    background-color: $white;
    color: $blue100;
  }
  span:last-child {
    background-color: $blue100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
  }
  span:last-child:hover {
    background-color: $white;
    color: $blue100;
  }
}

.addBox2 {
  border-radius: 5px;
  display: flex;
  font-family: $poppins;
  transition: all 0.3s;
  box-shadow: $shadow;
  cursor: pointer;
  span {
    color: $white;
    transition: all 0.3s;
    height: 40px;
    font-weight: 600;
    font-size: 0.9em;
  }
  span:first-child {
    background-color: $red60;
    color: $white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 2px solid $red60;
  }

  span:first-child:hover {
    background-color: $white;
    color: $red60;
  }
  span:last-child {
    background-color: $blue100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $blue100;
  }
  span:last-child:hover {
    background-color: $white;
    color: $blue100;
  }
}

.blueAddButton {
  display: flex;
  flex-direction: column;
  position: relative;

  .addItems {
    position: absolute;
    left: 0px;
    top: 110%;
    // font-size: .8rem;
  }
}

.notActive {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 102;
  cursor: pointer;
}
.editSurveyPencil {
  background-color: $gray60;
  padding: 0.5em 0.6em;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  cursor: pointer;
  i {
    color: $blue100;
    font-size: 1.2em;
  }
}

.quickButtonContainer {
  position: sticky;
  bottom: 0em;
  display: flex;
  gap: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 100;
  padding: 0.5em 1em;
}

.stick {
  position: sticky;
  top: 0.5em;
  width: 100%;
  background-color: transparent;
  z-index: 130;
  border-radius: 1em;
}

.shadow {
  border-radius: 1em;
  background-color: $white;
  box-shadow: $shadow;
}

.pageSection {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.addBottomOfPage {
  font-family: $poppins;
  font-size: 0.9rem;
  color: $gray100;
  transition: 0.3s all;
  &:hover {
    color: $blue100;
    cursor: pointer;
  }
}

.barDivider {
  height: 80%;
  width: 2px;
  background: $gray60;
  border-radius: 2px;
}

.addContainer {
  display: flex;
  gap: 5px;
  font-family: $poppins;
  font-size: 0.9em;
  height: 100%; // survey has gap of 10px, little extra room
  opacity: 1;
  position: absolute;
  width: 100%;
  left: -35px;
  align-items: center;
  justify-content: flex-start;
}
@include keyframes(showup) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.add {
  @include animation("showup .5s 1");
  color: $gray100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $white;
  z-index: 1;
  box-shadow: $shadow6;
  border-radius: $radius2;
  height: 25px;
  width: 25px;
  transition: 0.3s all;
  &:hover {
    background-color: $gray30;
  }
}

.addBtn {
  font-size: 0.9em;
  border-radius: $radius2;
  transition: all 0.3s;
  text-align: center;
  cursor: pointer;
  color: $gray110;
  background-color: $white;
  box-shadow: $shadow6;
  z-index: 1;
  padding: 0.3rem 0.5rem;

  &:hover {
    // color: $blue100;
    background-color: $gray30;
  }
}

.addBtnBig {
  font-size: 1em;
  border-radius: $radius2;
  transition: all 0.3s;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $blue100;
  color: $white;
  font-weight: 600;
  box-shadow: $shadow6;

  span {
    font-size: 0.7em;
    padding: 10px 30px;
    font-weight: 400;
  }

  i {
    color: $white;
  }

  &:hover {
    background-color: $white;

    color: $blue100;

    i {
      color: $blue100;
    }
  }
}

.addSection {
  color: $gray100;
}
.addSection:hover {
  color: $gray110;
}

.paste {
  color: $orange60;
}
.paste:hover {
  color: $orange100;
}

.sendText {
  font-family: $poppins;
  color: $gray110;
  font-size: 0.9em;
  padding: 1em 1em;
  .link {
    @include link;
  }
}

.tip {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  font-size: 0.8em;
  span {
    display: flex;
    gap: 0.5em;
  }
  a {
    font-weight: 500;
  }
  height: 100%;
  width: 100%;
  padding: 0em 1em;
  align-items: center;
}

.closing {
  top: 0.6rem;
  right: 1rem;
}

.endPieceClosing {
  bottom: 0.8rem;
  right: 1rem;
}

.poweredBy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 0.9em;
  font-family: $poppins;
  color: $gray110;

  // border: 1px solid black;

  a {
    @include link;
  }
}
