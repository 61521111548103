@import "assets/styles/globals.scss";

.container {
  background-color: $gray20;
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: fit-content;
  min-height: fit-content;
  border-radius: $radius2;
}

.error {
  @include row;
  justify-content: center;
  color: $red60;
  font-family: $poppins;
  font-size: 0.9rem;
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  color: $black;
}

.prof {
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.forThem {
  color: $gray100;
  font-size: .65rem;
}