@import "assets/styles/globals.scss";

.container {
  min-height: 400px;

  overflow: auto;
  // padding: 1rem;
  display: flex;
  flex-direction: column;
}

