@import "assets/styles/globals.scss";

.spacer {
  margin-bottom: 1rem;
}

.nameContainer {
  display: flex;
  align-items: flex-end;
  transition: .3s all;
  width: 100%;
  gap: 0px;
}

.number {
  font-family: $poppins;
  padding-top: .3rem;
  width: 100%;
  max-width: fit-content;
  font-size: 11pt;
  position: absolute;
  right: 2rem;
  bottom: .5rem;
}

.number2 {
  font-family: $poppins;
  padding-bottom: 5px;
  width: 100%;
  max-width: fit-content;
  font-size: 11pt;
}