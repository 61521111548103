@import "assets/styles/globals.scss";

.tableContainer2 {
  height: 100%;
  display: flex;

  overflow: auto !important;
  position: relative;
  padding-top: 1rem;
  // border: 2px solid black;

  &::-webkit-scrollbar {
    height: 16px; // Chrome's default scrollbar height on macOS
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1; // Chrome default scrollbar color
    border-radius: 8px; // Rounded edges like Chrome
    border: 4px solid transparent; // Creates spacing effect
    background-clip: padding-box; // Ensures inner part is visible
  }

  &::-webkit-scrollbar-track {
    background: $gray22; // Chrome default track color
  }
}


.table {
  border-spacing: 0;
  background-color: inherit;
  width: 100%;
  height: fit-content;
  box-shadow: $shadow6;
  border-radius: $radius2;
  // border: 1px solid blue;
  margin-top: 1rem;
  // overflow: hidden;
  

  .header {
    text-align: left;
    white-space: nowrap;
    height: fit-content;
    font-family: $poppins;
    color: $gray110;
    font-size: 0.8rem;
    z-index: 9;
    position: sticky;
    border-radius: $radius2;
    // box-shadow: $shadow6;
    top: -2px;
    border: 1px solid $gray60;


    th {
      cursor: pointer;
      font-weight: 500;
      padding: 0rem;
      overflow: visible;
      top: -1px;
      z-index: 10;
      position: sticky;
      background-color: $gray20;
      // border: 1px solid red;

      .headerContainer {
        background-color: $gray40;
        height: 40px;
        display: flex;
        align-items: center;
        @include header-6-text(black, 400, 0.8rem);
        white-space: nowrap;
        cursor: pointer;
        margin: 0;
        padding: 0.5rem 1rem;
        display: flex;
        gap: 0.5rem;
      }
    }

    tr {
      background-color: $white;
      border-radius: $radius2;
    }

    th:first-child {
      background-color: $gray20;
      border-top-left-radius: $radius2;
      border-bottom-left-radius: $radius2;
      .headerContainer {
        border-top-left-radius: $radius2;
        border-bottom-left-radius: $radius2;
      }
    }

    th:last-child {
      border-top-right-radius: $radius2;
      border-bottom-right-radius: $radius2;
      .headerContainer {
        border-right: none;
        border-top-right-radius: $radius2;
        border-bottom-right-radius: $radius2;
      }
    }


  }

  tbody {
    tr {
      background-color: $white;
    }

    td {
      font-family: $poppins;
      color: $black;
      font-size: 0.8em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-bottom: 1px solid $gray60;
      padding: 0.2rem 0;

      .tdvalue {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.15rem 0.5rem;
        height: 100%;
      }

      .tdcontent {
        display: flex;
        align-items: center;
      }
      .editIcon {
        opacity: 0;
        padding-right: 0.5rem;
        font-size: 1.1rem;
        transition: 0.2s all;
        &:hover {
          i {
            color: $blue100;
          }
        }
      }
      &:hover {
        .editIcon {
          opacity: 1;
        }
        background-color: $white;
      }

      .editBox {
        position: absolute;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        background-color: $white;
        padding: 0.5rem;
        padding-bottom: 0.7rem;
        box-shadow: $shadow6;
        border-radius: $radius2;
        width: fit-content;
        max-width: 400px;
        min-width: 300px;
        z-index: 1;
      }
    }
  }

  .nohover {
    &:hover {
      background-color: transparent;
    }
  }
  .hover {
    &:hover {
      background-color: $gray25;
    }
  }
}













.check {
  z-index: 0;
}

.header2 {
  z-index: 0;
  position: absolute;
  height: 100%;
  background-color: black;
  tr {
    background-color: black;
  }
}

.changeInput {
  transition: 0.3s all;
  border: 2px solid $gray60;
  border-radius: $radius1;
  padding: 0.3rem 0;
  padding-left: 0.3rem;
  outline-color: $blue100;
  width: 100%;
  font-family: $poppins;
  font-size: 0.8rem;
  height: 2.2rem;
}

.settingspopup {
  position: absolute;
  top: 100%;
  left: 50%; /* Start from the center */
  transform: translateX(-50%); /* Offset to truly center the pop-up */
  width: 250px;
  height: fit-content;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: $gray110;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
}

.thSettings {
  // border-right: 0.5px solid $white;
}

.headerOpen {
  background-color: $blue40;
}

.innerHeader {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  gap: 0.25rem;
}
.innerHeader2 {
  // display: flex;
  // justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mover {
  height: 100%;
  width: 10px;
  min-width: 10px;
  cursor: col-resize;
  transition: 0.3s all;
  display: flex;
  justify-content: flex-end;
  &:hover {
    .movercontent {
      background-color: $sapphire100;
    }
  }
  z-index: 5;

  .movercontent {
    width: 4px;
    min-width: 4px;
    height: 100%;
  }
}

.unmove {
  height: 30px;
  width: 8px;
}

.overlayContent {
  position: absolute;
  height: 100%;
  max-width: fit-content;
  background-color: $white;
  box-shadow: $shadow;
  opacity: 0.9;
  border-radius: $radius2;
  pointer-events: none; // Prevent interaction with the overlay
  background-color: $white;
  border-radius: $radius2;
  overflow: hidden;
  z-index: 1000; // Ensure the overlay is on top of other elements
}


.dragging {
  border-left: 2px solid $blue100;
  border-right: 2px solid $blue100;
  opacity: 0.5;
}

.draggingheader {
  cursor: grabbing;
  .headerContainer {
    border-left: 2px solid $blue100;
    border-right: 2px solid $blue100;
    border-top: 2px solid $blue100;
    opacity: 0.5;
    cursor: grabbing;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.emptyMessage {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  padding: 2em;
}

.textField {
  font-family: $poppins;
  min-width: 100%;
  height: 100%;
  padding: 5px 4px;
  border: none;
  outline: none;
  border-radius: $radius2;
  color: $gray110;
  background-color: transparent;
  &:active {
    box-shadow: $shadow;
    background-color: $white;
  }

  &:focus {
    box-shadow: $shadow;
    background-color: $white;
  }
}
