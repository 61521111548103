@import "assets/styles/globals.scss";

.container {
  display: flex;
  height: 100%;
}

.separateScroll {
  overflow: hidden;
}

.emailPreview {
  display: flex;
  flex-direction: column;
  background-color: $gray20;
  flex: 1 1 auto;
  overflow: auto;
}

.toFromContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.toFrom {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 2px solid $gray40;
  padding: 10px;
  color: $gray100;
  overflow: auto;
}

.from {
  color: $black;
}

.to {
  color: $gray110;
}

.subject {
  color: $black;
  font-weight: 500;
}

.preview {
  padding: 15px 10px 10px;
  border-bottom: 2px solid $gray20;
  background-color: $white;
  border-bottom-left-radius: $radius2;
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
  background-color: $gray20;
  // justify-content: space-around;
  overflow: auto;
  flex: 0 0 auto;
}

.title {
  font-size: 0.8rem;
  color: $gray100;
  border-bottom: 2px solid $gray40;
  padding-bottom: 5px;
  padding-left: 8px;
  // text-align: center;
}

.fields {
  display: flex;
  flex-direction: column;
  width: 270px;
  height: fit-content;
  gap: 5px;
  padding: 10px;
  font-family: $poppins;

  background-color: $white;
  box-shadow: $shadow;
  border-radius: $radius3;

  // overflow-y: auto;
  // overflow-x: hidden;
}

.pickContact {
  gap: 10px;
}

.searchField {
  font-family: $poppins;
  width: 250px;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  // gap: 3px;

  .label {
    color: $sapphire80;
    padding-left: 5px;
  }

  .search {
    font-family: $poppins;
    background-color: white;
    border-radius: $radius2;
    border: 2px solid $gray30;
    color: $gray110;
    outline: none;
    padding: 5px;
    transition: 0.3s all;

    &:hover {
      border: 2px solid $sapphire20;
    }
    &:focus {
      border: 2px solid $sapphire20;
    }

    &::placeholder {
      color: $gray100;
    }
  }
}

.contact {
  font-size: 0.8rem;
  text-align: center;
}

.anchor {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 120;
}

@keyframes grow {
  0% {
    height: 0px;
  }
}

.results {
  position: absolute;
  top: 5px;
  left: 0;

  @include animation(grow 0.3s);
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 240px;
  overflow-y: auto;

  background-color: $white;
  box-shadow: $shadow;
  border-radius: $radius3;
}

.option {
  padding: 5px;
  transition: 0.5s all;
  height: fit-content;

  &:hover {
    background-color: $gray20;
    cursor: pointer;
  }
}

.sendContainer {
  gap: 10px;

  .buttons {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.configTo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: -8px;
  padding-top: 3px;
}

.noData {
  @include col;
  align-items: center;
  font-family: $poppins;
  color: $gray100;
  padding-top: 10px;
  padding-bottom: 10px;
}

.textPreview {
  flex-grow: 1;
  @include row;
  justify-content: center;
  padding: 40px 75px;
}

.textWindow {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 5px;
  padding-top: 10px;
}

@include keyframes(bounceIn) {
  0% {
    transform: translateY(-100%);
  }

  87% {
    transform: translateY(5%);
  }

  100% {
    transform: translateY(0);
  }
}

.text {
  width: 85%;
  border-radius: 8px;
  padding: 5px;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  background-color: #efeeee;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  @include animation(bounceIn 0.3s ease-out);
}
