@import "assets/styles/globals.scss";

.textField {
  width: 100%;
  background-color: $blue5;
  border-radius: $radius1;
  border: 1px solid $info;
  font-family: $poppins;
  color: $black;
  font-size: 1rem;
  padding: 0.5rem 0rem;
  padding-left: 0.5rem;
  outline: none;
  overflow: none;
  transition: 0.3s all;

}
.textField:hover {
  border: 1px solid $blue100;
}
.textField:focus {
  border: 1px solid $blue100;
  // background-color: $gray25;
}

.textFieldLine {
  width: 100%;
  background-color: white;
  border: none;
  border-bottom: 2px solid $gray30;
  font-family: $poppins;
  color: $gray110;
  font-size: 1rem;
  outline: none;
  padding: 0px 10px;
  display: flex;
  overflow-x: scroll;
  overflow: hidden;
  
}
.textFieldLine:focus {
  transition: 0.5s;
  border-bottom: 2px solid $blue100;
}
.textField::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray100;
  opacity: 1; /* Firefox */
}

.textField:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray60;
}

.textField::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray60;
}

.password {
  -webkit-text-security: square;
}
.password::placeholder {
  -webkit-text-security: none;
}

.label {
  color: $seafoam100;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 300;
  // letter-spacing: 0.1em;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // padding: 0 0.1rem;
  padding: 0.2rem 0.1rem;
  align-items: flex-end;
  gap: 10px;
}

.inputBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: $white;
  border-radius: $radius2;
  border: 1px solid $white;
  box-shadow: $shadow6;
  outline: none;
  overflow: none;
  transition: 0.3s all;


  &:hover {
    border: 1px solid $blue100;
  }
  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: $poppins;
    color: $black;
    font-size: 1rem;
    padding: 0.5rem 0rem;
    padding-left: 0.7rem;
    width: 100%;

  }

  .icon {
    padding-left: 0.8rem;
    padding-right: 0.2rem;
    color: $gray110;
    font-size: 1.2rem;
  }
}

.focus {
  border: 1px solid $blue100;
}

.disabled {
  cursor: default;
  background-color: $gray30;
}

.invalidMessage {
  font-family: $poppins;
  color: $red60;
  font-size: 0.6rem;
}

.invalidBorder {
  border: 1px solid $red60;
  background-color: $red10;
}

.validMessage {
  font-family: $poppins;
  color: $blue100;
  font-size: 0.6rem;
}

.copy {
  padding-right: 10px;
  transition: .3s all;
  cursor: pointer;
  &:hover {
    color: $gray100;
  }
  &:active {
    color: $gray110;
  }
}

.copied {
  font-family: $poppins;
  color: $blue100;
  font-size: 0.7rem;
}

.modal {
  position: absolute;
  top: 100%;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: $shadow6;
  padding: 15px;
  z-index: 1000;
  width: fit-content;
  min-width: 100%;

  h4 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }

  ul {
    padding-left: 2px;
    margin: 5px 0;
    list-style-type: none;

    li {
      font-size: 12px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      i {
        font-size: 1.2rem;
      }
    }
  }
}

.textSuccess {
  color: green;
}

.textDanger {
  color: red;
}
