@import "assets/styles/globals.scss";

.page {
  background-color: $gray20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.top {
  background-color: $white;
  border-bottom: 1px solid $gray60;
  padding: 1rem;
}


.area {
  border: none;
  box-shadow: $shadow6;
  border-radius: $radius2;
  font-family: $poppins;
  height: 100px;
  padding: .5rem;
  background-color: $white;

  &:focus {
    background-color: $white;
    border: none;
  }
}
.noRoleSelected {
  position: relative;
  font-family: $poppins;
  font-style: italic;
  color: $gray110;
  padding: 2rem;
}

.textBox {
  display: flex;
  justify-content: space-between;
  gap: 7px;
  width: 100%;
}

.accordianContainer {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 25px;
  transition: all 0.5s;
  width: 100%;
}

.setting {
  box-shadow: $shadow6;
  padding: 1rem;
  border-radius: $radius2;
  flex: 1;
  min-width: 400px;
  background-color: $white;
  .header_5 {
    width: 100px;
  }
}

.item {
  padding: 5px 40px 5px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 250px;
}

.buttonsDiv {
  display: flex;
  height: 35px;
  width: 100%;
  gap: 2rem;
  position: sticky;
  bottom: 1rem;
  justify-content: flex-end;
}

@keyframes saved {
  from {
    color: $blue100;
  }
  to {
    color: $gray60;
  }
}

.saved {
  display: flex;
  gap: 0.5rem;
}

.content {
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
 flex-direction: column;
  }
}

.savedStatus {
  position: absolute;
  top: 15em;
  left: 17.5em;
  color: $gray60;
}
.roleSettings {
  height: 100%;
  width: 100%;
  min-width: 500px;
  padding: 2rem;

  overflow: auto;
}

.header {
  @include header-4-text($black);
  font-size: 1.2em;
  padding-left: 1em;
}

.header2 {
  @include header-3-text($black);
  margin: 0;
  font-weight: 400;
}
.description2 {
  @include description-text($gray110);
}

.permissions {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.allPermissions {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-width: 800px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.saving {
  font-size: 0.9rem;
  color: $gray60;
  align-self: flex-start;
}

.error {
  color: $red60;
}

.sidebar {
  min-width: 350px;
  max-width: 350px;
  min-height: 100px;
  height: 100%;
  padding: 0rem;
  // padding-right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: $white;
  border-right: 1px solid $gray60;


  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    border: none;
    border-bottom: 1px solid $gray60;
    height: fit-content;

    h3 {
      display: none;
    }
  }
}

.roleItem {
  padding: 1.5rem 1.25rem;
  // border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;

  &:hover {
    background: $gray20;
  }

  &.highlighted {
    background: $gray20;
  }
}
