@import "assets/styles/globals.scss";

.sectionContainer {
  border-radius: 1em;
  // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  background-color: white;
  padding: 0.5em;
}

.imageHolder {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
}