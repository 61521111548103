@import "assets/styles/globals.scss";

.headerContainer {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  gap: .75rem;
  height: fit-content;
  padding: .75rem;
  background-color: $white;
  border-bottom: 1px solid $gray60;
  width: 100%;
}


.signout {
    border: 1px solid $gray60;
    width: 35px;
    height: 35px;
    border-radius: $radius2;
    display: flex;
    align-items: center;
    position: relative;


    .signButton {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: .75rem;
        font-weight: 500;
        transition: .3s all;
        font-size: .7rem;
        border-radius: .42rem;
        display: flex;
        width: 100%;
        justify-content: center;
        &:hover {
          background-color: $gray40;
        }
    }

    .switch {
        position: absolute;
        top: 35px;
        right: 0px;
        z-index: 1;
        box-shadow: $shadow6;
        background-color: $white;
        padding: .5rem;
        border-radius: $radius1;
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        overflow: hidden;
    }

    .into {
        color: $gray110;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }
}


.menu_item {
    font-size: .9rem;
    padding: .5rem;
}


.link {
    font-size: .8rem;
}