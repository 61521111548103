@import "assets/styles/globals.scss";

.page {
  display: flex;
  flex-direction: column;
  width: 420px;
  overflow: auto;
  gap: 1rem;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1em;
  padding: 1rem;
  border-bottom: 1px solid $gray60;
}

.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
  border-top: 1px solid $gray60;
  background: $white;
  padding: 1rem;
}

.section {
  border-radius: $radius2;
  box-shadow: $shadow6;
  background-color: $white;
  padding: 1em;
  width: 400px;
  font-family: $poppins;
  color: $gray110;
  height: fit-content;
  margin-top: 1em;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  .header {
    font-weight: 600;
    // font-size: 1.1em;
  }
}

.notifBlock {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
  height: 100vh;
}

.none {
  text-align: center;
  color: $gray60;
  font-weight: 600;
  font-size: 0.8em;
  font-family: $poppins;
  
}
.seeMore {
  width: 100%;
  text-align: center;
  a {
    @include link;
  }
}

.search {
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
}

.wordDivider {
  color: $gray100;
  font-weight: 600;
  margin: 0em 1em;
  font-size: 0.8em;
  font-family: $poppins;
}

.pageSettingsContainer {
  // padding: 2px;
  border: 3px solid $blue40;
  border-left: 0px solid $blue40;
  //   border: 2px solid $blue100;
  // border: 4px solid $blue100;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  // max-width: 450px;
  font-family: $poppins;
  color: $gray110;
  font-weight: 400;
  background-color: white;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
}

.sortBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  //align-items: center;
  // border: 2px solid black;
}
.singleSetting {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 0px;
  align-items: center;
  // border: 2px solid gold;
}

.notificationContainer {
  border-radius: $radius2;
  padding: 0.5em;
  box-shadow: $shadow6;
  display: flex;
  align-items: center;
  gap: 7px;
  position: relative;
  cursor: pointer;
  background-color: $white;
  font-family: $poppins;
  margin: 0 1rem;

  .notread {
    height: 8px;
    width: 8px;
    background-color: $blue100;
    border-radius: 50%;
  }

  .read {
    height: 8px;
    width: 8px;
    // border: 1px solid $gray40;
    border-radius: 50%;
  }

  .avatar {
    max-width: 40px;
    // max-height: 40px;
    min-width: 40px;
    // min-height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }

  .threeDots {
    color: $gray110;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: $radius2;
    transition: 0.2s all;
    bottom: 0.5em;
    right: 1em;
    padding: 2px 5px;
    height: fit-content;

    &:hover {
      background-color: $gray25;
    }
  }

  .notification {
    flex-grow: 1;
    overflow: hidden;
    padding-left: 5px;
    .header {
      color: $black;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .notifTitle {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      // font-size: 0.8em;

      .time {
        font-size: 0.9em;
        color: $gray100;
      }
    }

    .notifHeader {
      color: $blue100;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 0.8em;
    }

    .notifBody {
      font-size: 0.8em;
      color: $gray110;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
      display: flex;
      gap: 1em;

      div {
        width: 58px;
        text-align: start;
        // background-color: blue;
      }
    }
  }
}

.notificationContainer:hover {
  background-color: $gray30;
}

.sender {
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  //padding-left: 30px;
  //border: 2px dashed black;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.senderTitle {
  width: 100%;
  font-size: 12px;
  //padding-left: 30px;
  //border: 2px dashed black;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.headerGridColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding-bottom: 10px;
}
.headerGridRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;

  // border: 1px solid black;
}

.title {
  width: 100%;
  font-size: 23px;
  font-weight: bold;
  padding-top: 20px;
  padding-left: 30px;
  // border: 2px dashed black;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.userInfoBox {
  width: 100%;
  display: flex;
  padding-left: 40px;
  flex-direction: row;
  // border: 2px dashed black;
  align-items: center;
  img {
    height: 40px;
    // width: 40px;
    border-radius: 50%;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.userProfileBox {
  display: flex;
  flex-direction: column;
}

.date {
  padding-top: 20px;
  padding-left: 20px;
  width: 20%;
  overflow: clip;
  // border: 1px dashed black;
}

.unreadNotifier {
  height: 100%;
  width: 100%;
  padding: 5px;
  color: $sapphire100;
  border-left: 10px solid white;
  border-right: 10px solid white;
}

.readNotifier {
  height: 100%;
  width: 100%;
  padding: 5px;
  color: $gray60;
  border-left: 10px solid white;
  border-right: 10px solid white;
}

.trashNotifier {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 10px;
  color: $sapphire100;
}

.trashNotifier:hover {
  color: red;
  cursor: pointer;
}

.starNotifier {
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  color: gold;
  border-left: 10px solid white;
  border-right: 10px solid white;
}

.starNotifierFill {
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  color: gold;
  border-left: 10px solid white;
  border-right: 10px solid white;
}

.messageBox {
  padding: 1px;
  height: 100%;
  width: 100%;
  font-size: small;
  max-height: 37px;
  overflow: hidden hidden;
  align-content: flex-end;

  //border: 2px dashed black;
}
.message {
  height: 100%;
  width: 100%;
  overflow: visible;
  //border: 2px dashed black;
}

.nothing {
  color: $gray110;
  font-family: $poppins;
  padding: 1em;
}

.navContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  min-height: 72px;
  background-color: $background;
}

.pageHeader {
  @include header-2-text;
  font-size: 1.6em;
  font-weight: 600;
  padding-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.player {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 40px;
  width: 40px;
}

.settingsBox {
  position: absolute;
  top: 1.2em;
  right: 0.5em;

  z-index: 110;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  // align-items: center;

  cursor: default;
  overflow: hidden;

  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    cursor: pointer;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}
