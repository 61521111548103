@import "assets/styles/globals.scss";

.from {
  gap: 1px;
  color: $black;
  font-size: 0.85rem;
}

.nameContainer {
  @include col;
  min-width: 20px;

  // width: 140px;
  width: fit-content;
  position: relative;
}

.fromInput {
  outline: none;
  border: none;
  text-align: right;
  padding: 0px;
  font-family: $poppins;
  border-bottom: 1px solid transparent;
  transition: 0.3s all;
  font-size: 0.85rem;
  width: fit-content;

  &:hover {
    border-bottom: 1px solid $sapphire20;
  }
  &:focus {
    border-bottom: 1px solid $sapphire20;
  }

  &::placeholder {
    color: $gray60;
  }
}

.at {
  padding-bottom: 3px;
}

.domainContainer {
  @include col;
  width: fit-content;
  position: relative;
}

.domain {
  transition: .3s all;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid $sapphire20;
  }
}

.dropDown {
  @include col;
  box-shadow: $shadow6;
  z-index: 1;
  border-radius: 0.25rem;
  max-height: 220px;
  overflow: auto;
  position: absolute;
  top: 120%;
  left: 0;
}

.option {
  flex-shrink: 0;
  font-size: 0.8rem;
  color: $black;
  padding: 2px 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: $white;
  transition: 0.3s all;
  cursor: pointer;
  text-align: right;

  &:hover {
    background-color: $gray20;
  }
}
