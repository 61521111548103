@import "assets/styles/globals.scss";

.page {
  background-color: $gray20;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.headerContainer {
  border-bottom: 1px solid $gray60;
  padding: 1rem;
  display: flex;
  // flex-direction: column;
  background-color: $white;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;
}

.headerFooter {
  padding: 1rem;
  gap: 1rem;
  display: flex;
  justify-content: center;
  height: fit-content;
  align-items: center;
}

.footer {
  position: sticky;
  bottom: 0;
  background-color: $white;
  padding: 1rem;
  border-top: 1px solid $gray60;
  z-index: 2;
}

.header_4 {
  color: $black;
}

.dropArea {
  flex: 1;
  min-height: 300px;
  border: 2px dashed $blue100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: $radius2;
  color: $black;
  font-size: 0.9rem;
}

.link {
  font-size: 0.9rem;
}

.drop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileContent {
  width: 100%;
  background-color: $white;
  border: 1px solid $gray60;
  border-radius: $radius1;
  overflow: auto;

}
.uploadFile {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.box {
  text-align: center;
  border-radius: $radius4;
  border: 2px solid transparent;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  align-items: center;
  background-color: $white;

  .square {
    border: 4px solid $gray60;
    height: 140px;
    width: 140px;
    border-radius: $radius4;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 3rem;
      color: $blue100;
    }
  }
  .text_3 {
    color: $gray110;
  }

  &:hover {
    background-color: $blue10;
  }
}

.boxfill {
  background-color: $blue10;
}

.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.maptable {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  th {
    text-align: start;
    @include normal-text($black);
    padding: 1rem 1rem;
    border-bottom: 1px solid $gray60;
  }

  td {
    padding: 1rem 1rem;
    height: 50px;
    border-bottom: 1px solid $gray60;
  }

  tr:last-child {
    td {
      border: none;
    }
  }

  td:last-child {
    padding: 1rem 2rem;
  }
  th:last-child {
    padding: 1rem 2rem;
  }
}

.uploading {
  text-align: center;
  @include normal-text($black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
}

.columninfile {
  width: 100%;
  border: 2px solid $gray40;
  background-color: $gray40;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: $radius2;
  padding: 0.6rem 0.5rem;
  font-family: $poppins;
  font-size: 0.9rem;
}

.information {
  border-radius: $radius2;
  background-color: $gray20;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 2rem;
  border: 2px solid $gray60;
  flex-wrap: wrap;
  gap: 1rem;
}

.label5 {
  color: $black;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $black;

  input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px;
    transition: 0.3s all;
    cursor: pointer;
    background-color: $gray60;
    border: 4px solid $gray60;
  }
  input:checked {
    background-color: $white;
    border: 5px solid $blue60;
  }
  input:not(:disabled):not(:checked):hover {
    background-color: $gray60;
    border: 5px solid $gray60;
  }
  input:disabled {
    // border: 2px solid $gray40;
    // background-color: $blue100;
    cursor: default;
  }
}

.loadingContainer {
  // border: 2px solid black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // padding: 2rem 0rem;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $gray110;
  font-size: 0.8em;

  input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px;
    transition: 0.3s all;
    cursor: pointer;
    background-color: $gray60;
    border: 4px solid $gray60;
  }
  input:checked {
    background-color: $white;
    border: 5px solid $blue60;
  }
  input:not(:disabled):not(:checked):hover {
    background-color: $gray60;
    border: 5px solid $gray60;
  }
  input:disabled {
    // border: 2px solid $gray40;
    // background-color: $blue100;
    cursor: default;
  }
}

.exampledata {
  color: $gray100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
