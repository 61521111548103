@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.calendar {
  border: 1px solid $gray60;
  border-radius: 8px;
  box-shadow: $shadow8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: $georgia;
  // overflow: visible;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 30px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.arrow {
  font-size: 16px;
  cursor: pointer;
  color: $black;
  padding: 10px;
  transition: 0.3s all;
  border-radius: 4px;

  &:hover {
    background-color: $gray60;
  }
}

.bigTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.month {
  font-size: 0.9rem;
  color: $black;
  cursor: pointer;
  padding: 0px 5px;
}

.year {
  font-size: 0.7rem;
  color: $gray100;
  position: relative;
  cursor: pointer;
}

.dropDown {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  z-index: 1;
}

.dropDownList {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: $shadow;
  overflow: auto;
}

.dropDownOption {
  padding: 5px 20px;
  transition: 0.3s all;
  text-align: center;
  cursor: pointer;
  background-color: $white;

  &:hover {
    background-color: $gray30;
  }
}

.row {
  display: flex;
  border-bottom: 1px solid $gray60;

  &:last-child {
    border: none;

    .day::before {
      height: 56px;
    }
  }
}

.dayName {
  flex-shrink: 0;
  padding: 3px 18px;
  background-color: $white;
  font-size: 0.75rem;
  border-right: 1px solid $gray60;
  text-align: center;

  width: 65px;

  &:last-child {
    border: none;
  }
}

.day {
  width: 65px;
  height: 55px;
  border-right: 1px solid $gray60;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  position: relative;
  box-sizing: border-box;

  &:last-child {
    border: none;
  }
  // &:hover {
  //   background-color: $white;
  //   box-shadow: $shadow8;
  // }
}

.date {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.65rem;
  width: fit-content;
  padding: 1px 4px 2px 3px;
  color: $black;
  // background-color: $white;
}

.holiday {
  position: absolute;
  bottom: 0px;
  left: 0px;

  width: 65px;
  font-size: 0.65rem;
  text-wrap: wrap;

  padding: 0px 3px 3px;
  color: $black;
}

.otherMonth {
  .date {
    color: $gray110;
  }
}

.green {
  background-color: $bpGreen;
}

.yellow {
  background-color: $bpYellow;
}

.red {
  background-color: $bpRed;
}

.inPast {
  opacity: .75;
  cursor: default;
}

.choosable::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 66px;
  height: 57px;
  border: 1px solid $gray110;
  opacity: 0;
  transition: 0.3s all;
  // @include animation(appear .4s ease-in-out);
  z-index: 1;
}

.choosable:hover::before {
  opacity: 1;
}

.choosable:first-child::before {
  // top: -1px;
  left: 0px;
  width: 65px;
}

// .chosen::before {
//   content: "";
//   position: absolute;
//   top: -1px;
//   left: -1px;
//   width: 66px;
//   height: 57px;
//   border: 2px solid $black;
//   @include animation(appear .4s ease-in-out);
//   z-index: 2;
// }

.check {
  color: $black;
  font-size: 30px;

  @include animation(appear 0.5s ease-in-out);
}

@include keyframes(appear) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
