@import "assets/styles/globals.scss";

.cancelAndSave {
  // position: absolute;
  // top: 10px;
  // right: 75px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  justify-content: flex-end;
  width: 100%;
  // padding-top: 20px;
  padding-right: 10px;
  // position: sticky;
  flex-wrap: wrap;
  top: 0em;
  // border: 2px solid black;

  i {
    color: $gray110;
    cursor: pointer;
  }
}

.unsubContainer {
  color: $gray110;
  display: flex;
  flex-direction: column;
  gap: 1em;

  i {
    cursor: pointer;
    &:hover {
      color: $red60;
    }
  }
}

.rotate {
  transform: rotate(90deg);
}

.answersContainer {
  // margin: 0px 50px 50px;
  // padding-top: 20px;
  // padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  // font-family: $poppins;
  gap: 1em;
  // font-size: 0.9em;
  .surveyTop {
    padding: 1rem;
    // padding-bottom: .5rem;
    display: flex;
    flex-direction: column;
    // gap: .5rem;
    cursor: pointer;
  }

  .surveyTitle {
    font-weight: 600;
    color: $gray110;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // background-color: $gray20;

    cursor: pointer;
  }

  .surveyContainer {
    background-color: $white;
    box-shadow: $shadow6;
    border-radius: $radius2;
    // padding: 1rem;
    display: flex;
    flex-direction: column;
    transition: 0.3s all;
    // gap: .5rem;
  }

  .questionsContainer {
    padding-bottom: 10px;

    .question {
      padding: 5px 20px;

      position: relative;
      overflow: visible;

      .hidden {
        color: $gray30;
      }
      .questionMask {
        color: $gray30;
        white-space: nowrap;
        &:hover {
          color: $blue100;
          cursor: pointer;
        }
      }

      width: fit-content;
      // background-color: $gray20;
      // border-bottom: 2px solid $gray60;

      .questionText {
        color: $gray100;
        font-style: italic;
      }
      .desc {
        font-style: italic;
        font-size: 0.8em;
        // padding-left: 30px;
        color: $sapphire60;
      }
      .answer {
        color: $black;
        padding-left: 30px;
        display: flex;
        gap: 15px;
        align-items: flex-start;
        font-family: $poppins;
        margin: 0.5rem 0;
      }
      .noAnswer {
        color: $gray100;
        padding-left: 30px;
      }
    }
  }
}

.deleteTooltip {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-family: $poppins;
  font-size: 1em;
  z-index: 100;
  color: $seafoam100;
  // line-height: 1.2em;

  background-color: white;
  border: 2px solid $gray60;
  padding: 10px;
  border-radius: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .choices {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .btn {
    cursor: pointer;
    padding: 0px 12px;
    transition: 0.5s all;
    background-color: $white;
    color: $seafoam100;
    border-radius: 5px;
    // display: flex;
    // align-items: center;
  }

  .btn:hover {
    // border-radius: 5px;
    color: $white;
    font-weight: 500;
  }

  .yes:hover {
    background-color: $red60;
  }

  .no:hover {
    background-color: $green100;
  }
}

.tabs {
  width: 100%;
  padding: 0rem 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $gray60;
  background-color: $white;
}

.none {
  font-family: $poppins;
  text-align: center;
  color: $gray60;
  font-weight: 600;
}

.seePastAnswers {
  color: $blue100;
  font-family: $poppins;
  font-size: 0.825em;
}

///
///
///
///
///
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: $background;
  height: 100%;
}

.pages {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $gray20;
  overflow: hidden;
}

.top {
  background-color: $white;
  // padding: 1em;
  // padding-right: 2.5em;
  // padding-bottom: 0px;
  // padding-top: 3em;
  // flex-wrap: wrap;
  display: flex;
  // grid-template-columns: auto auto;
  align-items: flex-start;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  // border: 2px solid black;
  // justify-content: space-evenly;
  width: 380px;
  min-width: 380px;
  border-right: 2px solid $gray40;
  overflow-y: auto;
}
.top:hover .editName {
  opacity: 1;

  &:hover {
    color: $gray100;
  }
}

.contactHeader {
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  flex-wrap: wrap;
  // border-bottom: 2px solid $gray40;
  width: 100%;
  padding: 1rem 2rem;
  padding-top: 1.3rem;
  background-color: $white;
  justify-content: space-between;
  // border: 2px solid black;
}

.editName {
  font-size: 1.5em;
  cursor: pointer;
  transition: 0.5s all;
  opacity: 0;
  color: $gray110;
}

.settingSection {
  display: flex;
  padding: 0.5em 1.5em;
  padding-bottom: 1em;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  @include header-3-text;
}

.description {
  @include description-text($gray100);
  font-size: 0.8em;
  font-weight: 500;
  padding-left: 2px;
  color: $gray100;
}

.header_3 {
  color: $black;
}

.header2 {
  @include header-3-text($black);
}

.description2 {
  @include description-text;
}

.colorHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.headerHolder {
  max-width: 450px;
}

.headerHolder2 {
  // max-width: 450px;
  display: flex;
  justify-content: space-between;
}

.avatar {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  // min-width: 60px;
  // min-height: 60px;
  border-radius: 50%;
  overflow: hidden;
  // margin-right: 25px;
  border: 2px solid $gray60;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 2em;
  color: $sapphire100;

  img {
    // height: 50px;
    width: 160px;
  }

  span {
    // border: 2px solid black;
    text-align: center;
  }
}

.avatar:hover {
  cursor: pointer;
}

.thearea {
  height: 100%;
  margin-bottom: 1em;
}
.header_5 {
  margin: 0;
  color: $black;
  font-weight: 500;
}

.userFields {
  padding: 1rem;
  padding-top: 1rem;
  font-size: 0.8em;
  border-bottom: 2px solid $gray40;
  height: fit-content;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-family: $poppins;
  i {
    margin-right: 0.5rem;
  }
}

.totalSomething {
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray100;
  font-weight: 500;
  padding-top: 4px;
  padding-left: 10px;

  .total {
    color: $info;
  }
}

.audiences {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
  padding-top: 15px;
  width: fit-content;
  // align-items: center;
  align-items: flex-start;
  font-family: $poppins;
  min-width: 130px;

  .audience {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .name {
    padding: 5px 10px;
    color: $sapphire100;
    background-color: $sapphire20;
    font-size: 0.9em;
    border-radius: $radius4;
  }

  .deleteFromAud {
    color: $red60;
    font-size: 0.6em;
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      // color: $gray110;
      font-size: 0.8em;
    }
  }

  .plusAudience {
    color: $gray100;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
    padding-left: 20px;
    transition: 0.3s all;
    &:hover {
      color: $blue100;
    }
  }
}

.pickAudiencesHeader {
  color: $seafoam60;
  font-weight: 500;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.addAudiences {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  font-family: $poppins;

  .audienceList {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 70vh;
    background-color: $gray20;
    width: 100%;
  }

  .newAudience {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all;

    .nameAndNumber {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      position: relative;

      span {
        color: $info;
      }

      .check {
        position: absolute;
        right: -2em;
        animation: FadeIn 0.3s forwards;
      }
    }
  }

  .unpicked:hover {
    background-color: $gray30;
  }

  .picked {
    color: $sapphire100;
    background-color: $sapphire20;
  }
}

.editNameContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;

  .editNameField {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.plus {
  cursor: pointer;
  transition: all 0.3s;
  color: $black;
  // margin-top: 10px;
  padding: 0.5rem 1rem;
  border-radius: $radius2;
  transition: all 0.5s;
  width: 100%;
  margin: 0.5rem 0;
  font-size: 0.9em;

  &:hover {
    color: $gray110;
    background-color: $gray30;
  }
}
.headerContainer {
  display: flex;
  align-items: center;
  // justify-content: end;
  gap: 1em;
}

.profileImage {
  border-radius: 50%;
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  font-weight: 500;
  // border: 2px solid $gray110;
  text-align: center;
  overflow: hidden;
  // box-shadow: $shadow;
  span {
    color: $white;
    // border: 2px solid black;
    text-align: center;
    letter-spacing: 1px;
  }
}

.pillContainer {
  background-color: $white;
  // border: 2px solid black;
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
}

.status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $blue100;
}

.greenstatus {
  background-color: $green100;
}
.yellowstatus {
  background-color: $yellow100;
}
.redstatus {
  background-color: $red60;
}

.header_4 {
  margin: 0;
  color: $black;
}
