@import "assets/styles/globals.scss";

.page {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.audName {
  color: $gray110;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: $poppins;
  font-size: 1.5em;
  text-align: center;
  border-radius: 1em;
  i {
    font-size: 0.6em;
    color: $gray60;
    cursor: pointer;
  }
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  gap: 0.2em;
  // margin-bottom: 20px;
}

.audDescription {
  color: $gray110;
  // text-transform: uppercase;
  font-weight: 400;
  // letter-spacing: 1px;
  font-family: $poppins;
  font-size: 1em;
  text-align: center;
  padding: 0em 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // margin-top: 5px;
}

.descContainer {
  display: flex;
  position: absolute;
  z-index: 12;
  min-height: 400px;
}

.bar {
  border-radius: 3px;
  background-color: $gray20;
  border: 2px solid $gray60;
  cursor: pointer;

  font-weight: 600;
  letter-spacing: 1px;
  font-family: $montserrat;
  font-size: 0.85em;
  color: $sapphire100;
}

.descriptionHeader {
  width: 25px;

  span {
    transform: rotate(-90deg) translate(-115px, 0px);
    display: flex;

    color: $sapphire100; //span bug turns it gray if not

    i {
      padding-top: 1px;
      transition: 0.75s all;
    }
    .rotate {
      transform: rotate(180deg);
    }
  }
}

.description {
  background-color: white;
  display: inline;
  font-family: $poppins;
  text-align: center;
  overflow-x: hidden;

  .divider {
    width: 85%;
    border: 1px solid $gray60;
    border-radius: 3px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  p {
    font-family: $poppins;
    font-size: 1em;
    text-align: left;
  }

  .contentContainer {
    border: 2px solid $gray60;
    border-right: none;
    border-radius: 3px 0px 0px 3px;
    width: 250px;
    height: 100%;
    padding: 10px 7px;
  }
}

.putAway {
  animation: putAway 0.5s;
  width: 0px;
  border: none;
}

.pullOut {
  animation: pullOut 0.75s;
  width: 250px;
}

@keyframes pullOut {
  0% {
    width: 0px;
  }
  100% {
    width: 250px;
  }
}

@keyframes putAway {
  0% {
    width: 250px;
  }
  100% {
    width: 0px;
  }
}

.growBox {
  display: flex;
  position: relative;
  // bottom: 0px;
  // border: 2px solid black;
  justify-content: right;
  height: 28px;
  margin: 0px -17px;
  // margin-top: 300px;
}

.growHeader {
  padding-right: 12%;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  i {
    position: relative;
    top: 1px;
    transition: 0.75s all;
  }
  .growRotate {
    transform: rotate(180deg);
  }
}

.growContent {
  padding: 1em;
  padding-top: 2em;
  //border: 2px solid black;
  // overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background;
  border-radius: 1em;
}

.growUp {
  animation: growUp 0.75s;
}

.shrink {
  animation: shrink 0.5s;
  height: 0px;
}

@keyframes growUp {
  0% {
    height: 0px;
  }
  100% {
    height: 647px;
  }
}

@keyframes shrink {
  0% {
    height: 647px;
  }
  100% {
    height: 0px;
  }
}

.pillContainer {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.oneOfThree {
  color: $seafoam100;
  font-weight: 700;
  font-family: $montserrat;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px 20px;
  margin: 0px 5px;
  cursor: pointer;

  &.selected {
    background-color: white;
    color: $sapphire100;
    border: 2px solid $gray40;
  }
  &.notSelected {
    background-color: $gray30;
    border: 2px solid $gray30;
  }
}

.searchContainer {
  display: flex;
  //border: 2px solid black;
  overflow: auto;
}

.searchBarsDiv {
  // border: 2px solid black;
  display: flex;
  // justify-content: space-around;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;

  .emailSearch {
    // width: 22%;
    width: 300px;
  }

  .nameSearch {
    padding-bottom: 12px;
    // width: 15%;
    // width: 200px;
  }
}

.searchBtn {
  margin-top: 50px;
  height: 33px;
  // position: relative;
  // left: 90%;
  display: flex;
  // flex-direction: row-reverse;
  margin-left: 172px;
}

.divider {
  div {
    border: 2px solid #b8b7b7aa;
    border-radius: 10px;
    height: 500px;
    margin: 5px 15px;
  }
}

.manualContent {
  display: flex;
}

.uploadHolder {
  // padding-top: 33px;
  height: 100%;
}

.manualHolder {
  padding-top: 50px;
}

.audienceTextFields {
  width: 100%;
  display: flex;
  justify-content: center;
  // margin: auto;
  padding-bottom: 20px;
  // margin-left: 10%;
}

.descriptionLabel {
  color: $sapphire100;
  font-family: $poppins;
  // font-size: .75em;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-left: 10px;
}

.backArrow {
  position: absolute;
  left: 0.8em;
  top: 0.2em;
  i {
    color: $gray110;
    font-size: 1.5em;
    cursor: pointer;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-height: fit-content;
  padding: 1rem 1.5rem;
  overflow: visible;
  border-bottom: 2px solid $gray40;
  align-items: center;
}

.name {
  font-family: $poppins;
  font-weight: 600;
  font-size: 1.4em;
  color: $gray110;
}

.table {
  display: flex;
  height: 100%;
  width: 100%;
  // border: 2px solid black;
  overflow: hidden;
  position: relative;
}

.uploads {
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.recentUploads {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  overflow: hidden;
  font-family: $poppins;
  width: 400px;

  span {
    @include header-4-text;
    padding: 0.5em 1em;
    padding-bottom: 0em;
    display: flex;
    justify-content: space-between;
  }
  i {
    &:hover {
      color: $red60;
      cursor: pointer;
    }
  }

  .upload {
    &:hover {
      background-color: $background;
      .view {
        opacity: 1;
      }
    }
    font-size: 0.9em;
    .view {
      position: absolute;
      right: 1em;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      color: $blue100;
      opacity: 0;
      transition: 0.3s all;
    }
    min-width: 330px;
    color: $gray110;
    position: relative;
    transition: 0.3s all;
    cursor: pointer;
    padding: 0.5em 1em;
    width: 100%;
  }
}

.viewPrev {
  font-family: $poppins;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 1em;
}

.profileImage {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  font-weight: 500;
  font-size: 0.8em;
  // border: 2px solid $gray110;
  text-align: center;
  // box-shadow: $shadow;
  span {
    color: $white;
    // border: 2px solid black;
    text-align: center;
    letter-spacing: 1px;
  }
}

.profileImage {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 0.25rem 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  font-weight: 500;
  font-size: 0.8em;
  // border: 2px solid $gray110;
  text-align: center;
  // box-shadow: $shadow;
  overflow: hidden;
  span {
    color: $white;
    // border: 2px solid black;
    text-align: center;
    letter-spacing: 1px;
  }
}

.bigOption {
  display: flex;
  flex-direction: column;
  width: 260px;
  color: $black;
  gap: 0.25rem;
  transition: 0.3s all;
  padding: 0.5rem;
}

.description2 {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  width: 100%;
  white-space: wrap;
  color: $gray110;
}

@include keyframes(slide-right) {
  0% {
    opacity: 0;
    width: 0px;
    // max-width: 400px;
  }
  100% {
    width: 400px;
    // max-width: 400px;
  }
}

// Define the reverse slide-right animation for exiting
@include keyframes(slide-left) {
  0% {
    width: 400px;
  }
  100% {
    width: 0px;
  }
}

.showFilters {
  background-color: $white;
  border-right: 2px solid $gray40;
  width: 400px;
  height: 100%;
  transition: 0.3s all;
  &.enter {
    @include animation("slide-right 0.3s forwards");
  }

  &.exit {
    @include animation("slide-left 0.3s forwards");
  }

  // margin: 1rem;
}

.filter {
  border-radius: $radius2;
  background-color: $sapphire20;
  color: $sapphire100;
  padding: 0.25rem 1rem;
  font-family: $poppins;
  font-size: 0.8rem;
  display: flex;
  gap: 0.25rem;
  cursor: pointer;

  .prop {
    color: $black;
  }
}
