@import "assets/styles/globals.scss";

.shell {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 7px;
  border-right: 1px solid $gray60;
}

.content {
  display: flex;
  justify-content: center;
  align-self: center;

  flex-wrap: wrap;
  gap: 10px;

  width: 200px;
}

.draggable {
  display: flex;
  flex-direction: column;
  width: 75px;
  // height: 65px;
  padding: 5px;
  padding-top: 0px;
  align-items: center;
  justify-content: space-between;
  font-family: $poppins;
  border-radius: $radius2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  background-color: $gray20;
  cursor: grab;
  overflow: hidden;

  transition: 0.3s all;

  &:hover {
    background-color: $gray40;
  }
}

.dragExample {
  background-color: $sapphire60;
  width: 50px;
  height: 50px;
}

.dragger {
  visibility: hidden;
  background-color: transparent;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;

  .visible {
    background-color: $gray20;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: $radius2;
  }
  &:active {
    cursor: grabbing;
  }
}

.selected {
  background-color: $gray60;
  &:hover {
    background-color: $gray60;
  }
}

.dragGrip {
  color: $gray60;
}

.draggableLabel {
  font-size: 0.75rem;
  color: $gray110;
  white-space: nowrap;
}

.contentIcon {
  // font-size: 2.5rem;
  color: $gray100;
  font-size: 1.25rem;
}

.unsub {
  font-size: 0.7rem;
  color: $gray100;
  text-decoration: underline;
  color: $blue60;
}

.fieldIcon {
  color: $blue60;
}

.answerIcon {
  color: $sapphire80;
}

.qNameIcon {
  color: #af7bb8;
}

.imageIcon {
  color: $red60;
}

.linkIcon {
  color: $blue100;
}

.sigIcon {
  color: $sapphire100;
}

.buttonIcon {
  position: relative;
  display: flex;
  align-items: center;
  color: $dullBlue30;
  height: fit-content;

  // border: 1px solid black;

  .mockButton {
    width: 2.25rem;
    height: 0.85rem;
    border-radius: 0.5rem;
    background-color: $dullBlue30;
  }

  .cursorIcon {
    color: $gray100;
    position: absolute;
    right: 0px;
    top: 6px;
    height: 0.85rem;
    font-size: 0.85rem;
  }
}
