@import "assets/styles/globals.scss";

.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  
}
.tableHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  min-width: 800px;
}

.thetable {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 500px;
  // min-width: 800px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 0;
  // align-items: flex-end;
}

.headerInner {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;

  // border: 2px solid black;
}

.titleTop {
  // border: 2px solid black;
}

.tpage {
  // display: flex;
  height: 100%;
  // max-height: 500px;
  border: 2px solid brown;
}

.tableTitle {
  @include header-3-text($gray110);
  margin: 0;
  font-weight: 400;
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
  // border: 2px solid black;
}

.table {
  border-radius: $radius2;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    weight: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
}

.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;

  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

.search {
  width: 300px;
  border-radius: $radius2;
}

@include keyframes(slide-right) {
  0% {
    opacity: 0;
    width: 0px;
    // max-width: 400px;
  }
  100% {
    width: 20rem;
    // max-width: 400px;
  }
}

// Define the reverse slide-right animation for exiting
@include keyframes(slide-left) {
  0% {
    width: 20rem;
  }
  100% {
    width: 0px;
  }
}

.showFilters {
  background-color: $white;
  border-left: 2px solid $gray40;
  border-top: 2px solid $gray40;
  // width: 10rem;
  height: 100%;
  transition: 0.3s all;
  position: absolute;
  z-index: 1;
  right: 0;
  &.enter {
    @include animation("slide-right 0.2s forwards");
  }

  &.exit {
    @include animation("slide-left 0.2s forwards");
  }

  // margin: 1rem;
}

.filters {
  position: relative;
}

.filter {
  border-radius: $radius2;
  background-color: $sapphire20;
  color: $sapphire100;
  padding: 0.25rem 1rem;
  font-family: $poppins;
  font-size: 0.8rem;
  display: flex;
  gap: 0.25rem;
  cursor: pointer;

  .prop {
    color: $black;
  }
}
