@import "assets/styles/globals.scss";

.multipleChoice {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 10px;
  padding-bottom: 5px;
  color: $black;
}

// .optionContainer {
//   height: 100%;
//   button {
//     overflow: hidden;
//     white-space: nowrap;
//     height: 100%;
//     border: 2px solid;
//     border-radius: 5px;
//     transition: 0.2s all;
//     font-weight: 500;
//     font-size: 0.9em;
//     width: 100%;
//     font-family: $poppins;
//   }
//   button:hover {
//     background-color: $blue100;
//     cursor: pointer;
//   }
// }

.barStyle {
  width: 100%;
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.9em;
  cursor: pointer;
  background-color: $white;
  border: 2px solid $blue100;
  white-space: nowrap;
  transition: 0.3s all;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;

  // &:hover {
  //   background-color: $blue100;
  //   color: $white;
  // }

  input {
    display: none;
  }
}

.chosen {
  background-color: $blue100;
  color: $white;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // -moz-transition: background-color 0.2s ease-in;
  // -o-transition: background-color 0.2s ease-in;
  // -webkit-transition: background-color 0.2s ease-in;
  // transition: background-color 0.2s ease-in;
  @include transition(background-color 0.2s ease-in);

  .checkmark {
    height: 21px;
    width: 21px;
    background-color: $gray30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      display: none;
      width: 9px;
      height: 9px;
      // color: $white;
      background-color: $white;
      border-radius: 50%;

      @include transition(background-color 0.2s ease-in);
    }

    &.multi {
      border-radius: 5px;

      &:after {
        content: "\F26E";
        background-color: transparent;
        width: 11px;
        height: 11px;
        //   color: $white;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: bold !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &:hover input ~ .checkmark {
    // background-color: $gray60;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    -webkit-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
  }

  input {
    display: none;

    &:checked ~ .checkmark {
      -moz-transition: background-color 0.2s ease-in;
      -o-transition: background-color 0.2s ease-in;
      -webkit-transition: background-color 0.2s ease-in;
      transition: background-color 0.2s ease-in;
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  .content {
    font-family: $poppins;
    font-weight: 400;
    font-size: 0.9em;
    cursor: pointer;
    max-width: 600px;
    width: 100%;
  }
}

.containerRank {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // -moz-transition: background-color 0.2s ease-in;
  // -o-transition: background-color 0.2s ease-in;
  // -webkit-transition: background-color 0.2s ease-in;
  // transition: background-color 0.2s ease-in;
  @include transition(background-color 0.2s ease-in);

  .checkmarkRank {
    height: 20px;
    width: 20px;
    background-color: $gray30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ranking {
      display: none;
      color: $white;
      font-size: 0.8em;
      font-family: $poppins;
    }

    // &:after {
    //   content: "";
    //   display: none;
    //   width: 11px;
    //   height: 11px;
    //   color: $white;
    //   background-color: $white;
    //   border-radius: 50%;

    //   @include transition(background-color 0.2s ease-in);
    // }

    &.multiRank {
      border-radius: 5px;

      &:after {
        // content: "\F26E";

        background-color: transparent;
        width: 11px;
        height: 11px;
        color: $white;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: bold !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &:hover input ~ .checkmarkRank {
    background-color: $gray60;
    border-radius: 5px;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    -webkit-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
  }

  input {
    display: none;

    &:checked ~ .checkmarkRank {
      background-color: $blue100;
      -moz-transition: background-color 0.2s ease-in;
      -o-transition: background-color 0.2s ease-in;
      -webkit-transition: background-color 0.2s ease-in;
      transition: background-color 0.2s ease-in;
      .ranking {
        display: block;
      }
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.otherOption:hover {
  .otherLabel {
    display: none;
  }
  .otherEntry {
    display: flex;
  }
}

.otherEntry {
  display: none;
}

.inputLine {
  font-family: $poppins;
  font-size: 1em;
  width: 100%;
  font-weight: 400;
  border: none;
  font-family: $poppins;
  outline: none;
  background-color: $gray20;

  border-radius: 5px;
}

.otherBar {
  width: 100%;
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.9em;
  cursor: pointer;
  outline: none;
  background-color: $white;
  border: 2px solid $blue100;
  white-space: nowrap;
  transition: 0.3s all;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;

  &::placeholder {
    color: $white;
  }
}
