@import "assets/styles/globals.scss";

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  align-items: flex-start;
  background-color: $gray22;
  // padding: 2rem;
  // padding-top: 1rem;
}

.content {
  width: 100%;
  height: 100%;
  background-color: $gray22;
  min-height: 100vh;
  padding: 0 2rem;
  // border-top: 2px solid $gray30;
}

.tabs {
  width: 100%;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
}

.text {
  @include description-text;
  font-size: 0.9em;

  span {
    color: $blue100;
  }

  a {
    font-weight: 600;
  }
  margin-bottom: 20px;
}

.tableHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  gap: 1rem;
}

.searchHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.searchBox {
  position: relative;
  z-index: 100;
}

.items {
  position: absolute;
  right: 0;
  top: 2.7em;
  background-color: $white;
  border-radius: $radius4;
  box-shadow: $shadow;
  width: 500px;
  overflow: hidden;
  // border: 2px solid black;

  .item {
    padding: 0.5em 1em;
    color: $gray110;
    cursor: pointer;
    font-family: $poppins;
    display: flex;
    justify-content: space-between;
    // font-size: .9em;
    gap: 1em;
    // width: fit-content;
    .type {
      color: $blue100;
      font-size: 0.8em;
    }

    &:hover {
      background-color: $background;
    }
  }
}
