@import "assets/styles/globals.scss";

.tiedToContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem 1rem;
}

.tiedToHeader {
  color: $seafoam100;
  font-family: $poppins;
  // cursor: pointer;
  font-weight: 500;
}

.tiedSurveys {
  min-width: 150px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.5rem;
}

.tiedSurvey {
  font-size: 0.8rem;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 25px;
  font-family: $poppins;
  transition: 0.3s all;
  padding: 2px 5px;
  border-radius: 5px;
}

.deleteSurvey {
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: $red100;
  }
}

.addSurvey {
  padding-left: 5px;
  width: fit-content;
  cursor: pointer;
  font-size: 0.8rem;
  transition: 0.3s all;
  color: $gray100;

  &:hover {
    color: $gray110;
  }
}

.addProjectAnchor {
  position: relative;
}

.addProjects {
  position: absolute;
  z-index: 5;
  top: -40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;

  .search {
    background-color: $white;
    border-radius: 1em;
    box-shadow: $shadow3;
  }

  .searchSurveys {
    width: 100%;
    background-color: white;
    border-radius: $radius4;
    border: 2px solid $white;
    font-family: $poppins;
    color: $gray110;
    font-size: 1em;
    outline: none;
    padding: 8px 10px;
    overflow: none;
    transition: 0.3s all;

    &:hover {
      border: 2px solid $sapphire60;
    }
  }

  .projectOptions {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 1em;
    box-shadow: $shadow3;
    max-height: 400px;
    overflow: auto;
  }

  .projectOption {
    padding: 8px 10px;
    font-family: $poppins;
    transition: 0.3s all;
    cursor: pointer;

    &:hover {
      background-color: $gray40;
    }
  }
}

.saveTies {
  display: flex;
  padding-top: 20px;
  align-self: center;
  gap: 15px;
  align-items: center;
}
