@import "assets/styles/globals.scss";

.loadingAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  // height: 100%; /* or set a fixed height if needed */
  width: 100%;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 6px solid $gray40;
  border-top-color: $blue100; /* A vibrant blue that fits many modern UIs */
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}