@import "assets/styles/globals.scss";

.container {
  // @include animation(show 0.5s 1);
  background-color: $white;
  padding-top: 1rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem 2.5rem;
  overflow: auto;
}

// @include keyframes(show) {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

.logic {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  font-family: $montserrat;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 1px;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0.3em;
  right: 0.5em;
  z-index: 100;
}

.conditions {
  display: flex;
  flex-direction: column;
  // gap: 1em;
}

.conditionShell {
  display: flex;
  gap: 8px;
  font-family: $poppins;
  color: $gray110;
}

.trash {
  height: 100%;
  display: flex;
  align-items: center;
  i {
    color: $gray110;
    cursor: pointer;
    transition: 0.3s all;
  }
  i:hover {
    color: $red60;
  }
}

.bar {
  width: 2px;
  background-color: $gray30;
}

.condition {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
}

.row {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;

  span {
    padding-bottom: 10px;
  }
}

.group {
  display: flex;
  flex-direction: column;
  // gap: 1em;
}

.addBox {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.questionName {
  font-family: $poppins;
  color: $gray110;
  padding-bottom: 5px;
  font-size: 0.95em;
}

.apply {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid $blue20;
}

.label {
  color: $seafoam100;
  font-family: $poppins;
  font-size: 0.9em;
  letter-spacing: 0.1em;
}

.shortHandList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 10px;
}

.shortHand {
  font-family: $poppins;
  padding: 3px 7px;
  border-radius: 5px;
  background-color: $gray22;
  width: fit-content;
  color: $black;

  transition: 0.3s all;
  box-shadow: $shadow8;

  &:hover {
    cursor: pointer;
    background-color: $seafoam10;
  }
}

.listTitle {
  color: $sapphire80;
  // color: $black;
  border-bottom: 2px solid $sapphire20;
  width: fit-content;
  padding: 0px 20px 0px 10px;
  margin-bottom: 2px;
}

.downstream:hover {
  background-color: #f7c594;
}
.upstream:hover {
  // background-color: #c8e0ba;
  background-color: #dce8d5;
}
.groupShortHand:hover {
  background-color: #cadde3;
}
.plusCondition {
  background-color: $white;
  &:hover {
    background-color: $gray30;
  }
}

.logicIcon {
  display: flex;
  align-items: center;
  color: $green100;
}

.selectGroupOpHolder {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 5px;
}

.selectClass {
  font-size: .75rem;
}
