body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dragging {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:focus {
  outline: none !important;
}

.audienceSelect {
  width: 100%;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 0.5rem;
  outline: none !important;
}

.audienceSelect__control:focus {
  outline: none !important;
}

/* 🎨 Styles for the dropdown */
.audienceSelect__control {
  border: 2px solid white !important;
  border-radius: 0.5rem !important;
  color: white !important;
  cursor: pointer !important;
  outline: none !important;
}

/* 🎨 When focused */
.audienceSelect__control--is-focused {
  /* border-color: #15bcc7 !important; */
  outline: none !important;
  box-shadow: 0 0 1px 1px #15bcc7 !important;
}

/* 🎨 Dropdown menu */
.audienceSelect__menu {
  border-radius: 0.5rem !important;
  outline: none !important;
  z-index: 100 !important;
}

/* 🎨 Hover */
.audienceSelect__option--is-focused {
  background-color: #e9ecef !important;
}

/* 🎨 Options */
.audienceSelect__option {
  padding: 10px;
  font-size: 16px;
  color: black;
  cursor: pointer !important;
}

/* 🎨 Selected Option */
.audienceSelect__option--is-selected {
  background-color: #7fcfd3 !important;
  color: black !important;
}

.smallSelect {
  width: 100%;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 0.25rem;
  outline: none !important;
}

.smallSelect__control:focus {
  outline: none !important;
}

/* 🎨 Styles for the dropdown */
.smallSelect__control {
  border: 0px solid #15bcc7 !important;
  border-radius: 0.25rem !important;
  color: white !important;
  cursor: pointer !important;
  outline: none !important;
  /* height: 30px !important; */
  min-height: 34px !important;
  margin: 0 !important;
  padding: 0 !important;
  /* border: 1px solid black !important; */
}

.smallSelect__value-container {
  /* height: 30px !important; */
  min-height: 30px !important;
  margin: 0 !important;
  padding: 0 !important;
  /* border: 1px solid black !important; */
}

.smallSelect__single-value {
  height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 6px 7px !important;
  /* border: 1px solid black !important; */
}

.smallSelect__dropdown-indicator {
  height: 20px !important;
  min-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 5px !important;
}

.smallSelect__indicators-container {
  height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 5px !important;
}

.smallSelect__clear-indicator {
  height: 20px !important;
  min-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 5px !important;
}
.smallSelect__multi-value {
  /* height: 20px !important;
  min-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important; */
  margin-left: 5px !important;
  
}

.smallSelect__multi-value-remove {
  color: gray
}

.smallSelect__input {
  /* height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 0 !important; */
  margin-left: 5px !important;
  /* border: 1px solid black !important; */
}
.smallSelect__placeholder {
  /* height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 0 !important; */
  margin-left: 7px !important;
  /* border: 1px solid black !important; */
}

/* 🎨 When focused */
.smallSelect__control--is-focused {
  /* border-color: #15bcc7 !important; */
  outline: none !important;
  box-shadow: 0 0 1px 1px #15bcc7 !important;
}

/* 🎨 Dropdown menu */
.smallSelect__menu {
  border-radius: 0.5rem !important;
  outline: none !important;
  z-index: 100 !important;
}

/* 🎨 Hover */
.smallSelect__option--is-focused {
  background-color: #e9ecef !important;
}

/* 🎨 Options */
.smallSelect__option {
  padding: 10px;
  font-size: 16px;
  color: black;
  cursor: pointer !important;
}

/* 🎨 Selected Option */
.smallSelect__option--is-selected {
  background-color: #7fcfd3 !important;
  color: black !important;
}








.dataInputSelect {
  width: 100%;
  font-size: 0.7rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 0.25rem;
  outline: none !important;
}

.dataInputSelect__control:focus {
  outline: none !important;
}

/* 🎨 Styles for the dropdown */
.dataInputSelect__control {
  border: 0px solid white !important;
  border-radius: 0.25rem !important;
  color: white !important;
  cursor: pointer !important;
  outline: none !important;
  /* height: 30px !important; */
  min-height: 30px !important;
  margin: 0 !important;
  padding: 0 !important;
  /* border: 1px solid black !important; */
}

.dataInputSelect__value-container {
  /* height: 30px !important; */
  min-height: 34px !important;
  margin: 0 !important;
  padding: 0 !important;
  /* border: 1px solid black !important; */
}

.dataInputSelect__single-value {
  height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 6px 7px !important;
  /* border: 1px solid black !important; */
}

.dataInputSelect__dropdown-indicator {
  height: 20px !important;
  min-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 5px !important;
}

.dataInputSelect__indicators-container {
  height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 5px !important;
}

.dataInputSelect__clear-indicator {
  height: 20px !important;
  min-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 5px !important;
}
.dataInputSelect__multi-value {
  /* height: 20px !important;
  min-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important; */
  margin-left: 5px !important;
  
}

.dataInputSelect__multi-value-remove {
  color: gray
}

.dataInputSelect__input {
  /* height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 0 !important; */
  margin-left: 5px !important;
  /* border: 1px solid black !important; */
}
.dataInputSelect__placeholder {
  /* height: 30px !important;
  min-height: 30px !important;
  margin: 0 !important;
  padding: 0 !important; */
  margin-left: 7px !important;
  /* border: 1px solid black !important; */
}

/* 🎨 When focused */
.dataInputSelect__control--is-focused {
  /* border-color: #15bcc7 !important; */
  outline: none !important;
  box-shadow: 0 0 1px 1px #15bcc7 !important;
}

/* 🎨 Dropdown menu */
.dataInputSelect__menu {
  border-radius: 0.5rem !important;
  outline: none !important;
  z-index: 100 !important;
}

/* 🎨 Hover */
.dataInputSelect__option--is-focused {
  background-color: #e9ecef !important;
}

/* 🎨 Options */
.dataInputSelect__option {
  padding: 10px;
  font-size: 16px;
  color: black;
  cursor: pointer !important;
}

/* 🎨 Selected Option */
.dataInputSelect__option--is-selected {
  background-color: #7fcfd3 !important;
  color: black !important;
}

