@import 'assets/styles/globals.scss';

.container {
  font-size: .85rem;
}

.title {
  border-bottom: 2px solid $sapphire20;
  padding: 0px 30px;
  font-size: 1.3rem;
  color: $sapphire80;
  font-weight: 500;
}

.thunder {
  font-size: 2rem;
  color: $gray110;
}

.heading {
  white-space: nowrap;
  color: $sapphire80;
}

.info {
  color: $gray110;
  font-size: .8rem;
}

.label {
  color: $gray110;
  font-size: .85rem;
  white-space: nowrap;
  transition: .7s all;
}

.divider {
  align-self: stretch;
  width: 2px;
  background: $gray60;
}

.smallLabel {
  color: $gray110;
  font-size: .8rem;
  white-space: nowrap;
}

.disabled {
  color: $gray60;
}

.select {
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray35;
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;
  padding: 3px;
  font-size: 0.8rem;
  color: $gray110;
}

.select:hover {
  background-color: $gray60;
}

.runTime {
  color: $sapphire80;
}

.subHeader {
  font-size: 1rem;
  color: $sapphire80;
  border-bottom: 1.5px solid $sapphire20;
  padding: 0px 20px;
  width: fit-content;
  align-self: center;
}

.subSub {
  width: fit-content;
  font-size: .85rem;
  color: $sapphire80;
  padding: 0px 20px 0px 10px;
  border-bottom: 1.5px solid $sapphire20;
}

.dbConnections {
  width: fit-content;
  font-size: .85rem;
  color: $black;
}