@import "assets/styles/globals.scss";

.backdrop {
  justify-content: center;
  padding: 1rem;
  background: $gray20;
}

.preview {
  font-family: $poppins;
  border: 1px solid $gray60;
  border-radius: $radius1;
  background: $white;
}

.meta {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid $gray60;
}

.label {
  font-size: .9rem;
  color: $gray100;
}

.email {
  width: 600px;
  min-height: 400px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.noData {
  text-align: center;
  color: $gray100;
  padding-top: 10px;
  padding-bottom: 10px;
}