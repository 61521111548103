@import "assets/styles/globals.scss";



.modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-family: $poppins;
  background-color: $gray20;
  padding: 2rem;
}

.prompt {
  color: $gray110;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}